import { useEffect } from 'react';
import TextInput from '../../components/Form/TextInput';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useGetActiveEventNamesQuery, useGetActiveIndustriesQuery, useGetActiveActivityTypesQuery } from '../../redux/features/events/eventsApiSlice';

interface EventOption {
    id: number;
    event: string;
}

interface IndustryOption {
    id: number;
    name: string;
}

interface ActivityTypeOption {
    id: number;
    name: string;
}

const ActivityForm = ({ handleClose, handleSubmit, isSubmitting, initialValues }: any) => {
    const validationSchema = Yup.object().shape({
        activityName: Yup.string().required('Este campo es obligatorio'),
        startTime: Yup.string().required('Este campo es obligatorio'),
        endTime: Yup.string().required('Este campo es obligatorio'),
        description: Yup.string().required('Este campo es obligatorio'),
        eventId: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero'),
        industryId: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero'),
        activityTypeId: Yup.number().required('Este campo es obligatorio').integer('Debe ser un valor entero'),
        breakTime: Yup.number()
            .required('Este campo es obligatorio')
            .integer('Debe ser un valor entero')
            .min(0, 'Debe ser mayor o igual a 0'),
        additionalTime: Yup.number()
            .required('Este campo es obligatorio')
            .integer('Debe ser un valor entero')
            .min(0, 'Debe ser mayor o igual a 0')
    });

    const defaultValues = {
        activityName: '',
        startTime: '',
        endTime: '',
        description: '',
        visible: 1,
        eventId: 0,
        industryId: 0,
        activityTypeId: 0,
        breakTime: 0,  
        additionalTime: 0  
    };

    const mergedInitialValues = { ...defaultValues, ...initialValues };

    const { data: eventNames = [], isLoading: isLoadingEventNames, refetch: refetchEventNames } = useGetActiveEventNamesQuery({});
    const { data: industries = [], isLoading: isLoadingIndustries, refetch: refetchIndustries } = useGetActiveIndustriesQuery({});
    const { data: activityTypes = [], isLoading: isLoadingActivityTypes, refetch: refetchActivityTypes } = useGetActiveActivityTypesQuery({});

    useEffect(() => {
        refetchEventNames();
        refetchIndustries();
        refetchActivityTypes();
    }, [refetchEventNames, refetchIndustries, refetchActivityTypes]);

    return (
        <Formik
            initialValues={mergedInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            <Form>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <TextInput name="activityName" label="Actividad" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput type="datetime-local" name="startTime" label="Fecha Comienzo" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput type="datetime-local" name="endTime" label="Fecha Término" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput type="number" name="breakTime" label="Tiempo de Descanso (minutos)" required={true} />  
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput type="number" name="additionalTime" label="Tiempo Adicional (minutos)" required={true} />  
                    </div>
                    <div className='col-12 mb-3'>
                        <TextInput name="description" label="Descripción" required={true} />
                    </div>
                    <div className='col-12 mb-3'>
                        <label htmlFor="eventId">Evento</label>
                        <Field as="select" name="eventId" className="form-control" required>
                            <option value="">Seleccione un evento</option>
                            {isLoadingEventNames ? (
                                <option value="">Cargando eventos...</option>
                            ) : (
                                eventNames.map((event: EventOption) => (
                                    <option key={event.id} value={event.id}>
                                        {event.event}
                                    </option>
                                ))
                            )}
                        </Field>
                    </div>
                    <div className='col-12 mb-3'>
                        <label htmlFor="industryId">Industria</label>
                        <Field as="select" name="industryId" className="form-control" required>
                            <option value="">Seleccione una industria</option>
                            {isLoadingIndustries ? (
                                <option value="">Cargando industrias...</option>
                            ) : (
                                industries.map((industry: IndustryOption) => (
                                    <option key={industry.id} value={industry.id}>
                                        {industry.name}
                                    </option>
                                ))
                            )}
                        </Field>
                    </div>
                    <div className='col-12 mb-3'>
                        <label htmlFor="activityTypeId">Tipo de Actividad</label>
                        <Field as="select" name="activityTypeId" className="form-control" required>
                            <option value="">Seleccione un tipo de actividad</option>
                            {isLoadingActivityTypes ? (
                                <option value="">Cargando tipos de actividades...</option>
                            ) : (
                                activityTypes.map((type: ActivityTypeOption) => (
                                    <option key={type.id} value={type.id}>
                                        {type.name}
                                    </option>
                                ))
                            )}
                        </Field>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? 'Guardando...' : 'Guardar'}
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

export default ActivityForm;
