
import { useEffect, useState } from 'react';

/* variables tabla */
import Tabla from '../../../components/Tabla/Tabla';
import { GridColDef } from '@mui/x-data-grid';
import { usuario_html } from '../../../utils/meetings';
import { personalData } from '../../../interfaces/dimentions';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../../redux/features/user/userSlice';
import GetUserSolicitudesRecibidas from '../../../hooks/meetings/GetUserSolicitudesRecibidas/GetUserSolicitudesRecibidas';
import GetUserSolicitudesEnviadas from '../../../hooks/meetings/GetUserSolicitudesEnviadas/GetUserSolicitudesEnviadas';
import { useGetUserSolicitudesRecibidasMutation, usePostUserRespuestaSolicitudRecibidaMutation, usePostCreateZoomMeetingMutation } from '../../../redux/features/meetings/meetingsApiSlice';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import { useGetUserSolicitudesEnviadasMutation, useGetUserMeetingRequestTimesMutation } from '../../../redux/features/meetings/meetingsApiSlice';
import { selectUserSolicitudesEnviadas, setUserSolicitudesEnviadas } from '../../../redux/features/meetings/meetingsSlice';
import { useDispatch } from 'react-redux';
import "./MeetingsSolicitudes.scss";
import { changeDateTimeZone, changeDonatedTimeZone } from '../../../utils/utilsForms';
import GetActualTimeZone from '../../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import moment from "moment"
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
  } from '@mui/material';

const MeetingsSolicitudesRecibidas = ({ handleOpenDetalleSolicitud }: any) => {

    const actual_time_zone = GetActualTimeZone()

    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let user_id = user_personal_data.Id
    

    const user_solicitudes_enviadas: any = useSelector(selectUserSolicitudesEnviadas) //acceder a datos del usuario logueado

    const [getSoliciudesEnviadas, { isLoadingGet }]: any = useGetUserSolicitudesEnviadasMutation()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const textMeetings = useSelector(selectCurrentDictonary).meetings
    
    //new modal
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null);

    const handleOpenCancelConfirmation = (id_solicitud: number) => {
        setSelectedRequestId(id_solicitud);
         
        setOpenCancelConfirmation(true);
    };
    
    useEffect(() => {
        if (user_id) {
            handleReload()
        }
    }, [user_personal_data]);

    async function handleReload() {
        setIsLoading(true)
         const res: any = await getSoliciudesEnviadas({ user_id: user_id });
         const resData = JSON.parse(res.data.responseData)
         dispatch(setUserSolicitudesEnviadas({ user_solicitudes_enviadas: resData }))
         setIsLoading(false)

          
    
        
    }


    let datos: any = []
    if (user_solicitudes_enviadas) {
        for (let i = 0; i < user_solicitudes_enviadas.length; i++) {
            const element = user_solicitudes_enviadas[i];
            if (!(element.RequestStatusId == 2 || element.RequestStatusId == 1 || element.RequestStatusId == 3)) {
                datos.push({

                    ConfirmDateTime: element.ConfirmDateTime,
                    CreatedAd: element.CreatedAd,
                    id: element.Id,
                    MeetingTypeId: element.MeetingTypeId,
                    MeetingTypeName: element.MeetingTypeName,
                    RequestStatusId: element.RequestStatusId,

                    UserRequestId: element.UserRequestId,
                    UserRequestLastName: element.UserRequestLastName,
                    UserRequestName: element.UserRequestName,
                    UserRequestProfilePicture: element.UserRequestProfilePicture,
                    UserRequestRoles: element.UserRequestRoles,

                    UserRequestedId: element.UserRequestedId,
                    UserRequestedLastName: element.UserRequestedLastName,
                    UserRequestedName: element.UserRequestedName,
                    UserRequestedProfilePicture: element.UserRequestedProfilePicture,
                    UserRequestedRoles: element.UserRequestedRoles,

                    UserRequestedTimeId: element.UserRequestedTimeId, // FALTAN DATOS DE LA HORA DONADA

                    RequestStatusReason: element.RequestStatusReason,
                    UserRequestedEndTime: element.UserRequestedEndTime,
                    UserRequestedStartTime: element.UserRequestedStartTime,
                    UserRequestedTimeZoneOffset: element.UserRequestedTimeZoneOffset,


                })
            }
        }
        
    }

    const [postUserResponderSolicitud, { isLoadingPostUserResponderSolicitud }]: any = usePostUserRespuestaSolicitudRecibidaMutation()

    async function handleClickResponderSolicitud(id_solicitud: number, status: number) {
        let new_alert = { id: "aceptando_solicitud", type: "loading", title: textMeetings.param_1, desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))

        try {
            let item = {
                "meetingRequestId": id_solicitud,
                "meetingRequestStatusId": status,
                "reason": ""
            }
            const res: any = await postUserResponderSolicitud({ body: item });
            const resdata = JSON.parse(res.data.responseData)


            dispatch(deleteAlert({ alert_id: "aceptando_solicitud" }));
            let new_alert2 = { id: "aceptando_solicitud_correctamente", type: "success", title: textMeetings.param_2, desc: "", close: true }
            dispatch(addAlert({ alert: new_alert2 }))

        } catch (error) {
            dispatch(deleteAlert({ alert_id: "aceptando_solicitud" }));
            let new_alert2 = { id: "aceptando_solicitud_incorrectamente", type: "error", title: textMeetings.param_3, desc: "", close: true }
            dispatch(addAlert({ alert: new_alert2 }))
        }
    }


    function opciones_html(id_solicitud: number, solicitud: any, RequestStatusId: number) {
        let btn_cancelar_html = <></>
        if (RequestStatusId != 1 && RequestStatusId != 2 && RequestStatusId != 3) { // si esta rechazada o cancelada no se puede rechazar la solicitud
            btn_cancelar_html = <button className="btn btn-danger btn-block p-1" onClick={() => handleOpenCancelConfirmation(id_solicitud)} >{textMeetings.btn_cancel}</button>
        }
        return (
            <div className='d-flex flex-column justify-content-center align-items-center'>
                {btn_cancelar_html}
                <button className="btn btn-outline-dark btn-block p-1" onClick={() => handleOpenDetalleSolicitud(0, id_solicitud, solicitud)}>
                    {textMeetings.th_opciones}
                </button>
            </div>
        )
    }
const handleCancelConfirmation = () => {
        if (selectedRequestId !== null) {
            handleClickResponderSolicitud(selectedRequestId, 3);
        }
        setOpenCancelConfirmation(false);
    };
    /* TABLA TIEMPOS DONADOS */
// Añadir al final del componente, antes del return
const cancelConfirmationDialog = (
    <Dialog open={openCancelConfirmation} onClose={() => setOpenCancelConfirmation(false)}>
        <DialogTitle>{textMeetings.confirmacion}</DialogTitle>
        <DialogContent>
            <p>{textMeetings.modal_solicitudes_realizadas_por_mi_cancelar}</p>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancelConfirmation} color="primary">
                {textMeetings.positivo}
            </Button>
            <Button onClick={() => setOpenCancelConfirmation(false)} color="primary">
                {textMeetings.negativo}
            </Button>
        </DialogActions>
    </Dialog>
);

    let columnas: GridColDef[] = [
        {
            headerName: textMeetings.table_1.header_1, description: "Fecha", field: "CreatedAd", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let CreatedAd = row.CreatedAd
                let dict_createdAd = changeDateTimeZone(actual_time_zone, CreatedAd)

                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                {moment(dict_createdAd.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                            </div>
                            <div>
                                {dict_createdAd.time}

                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_2, description: "Fecha", field: "HoraReunion", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let UserRequestedStartTime = row.UserRequestedStartTime
                let UserRequestedEndTime = row.UserRequestedEndTime
                let fecha_inicio = UserRequestedStartTime.split("T")[0]
                let hora_inicio = UserRequestedStartTime.split("T")[1].split(":")[0] + ":" + UserRequestedStartTime.split("T")[1].split(":")[1]
                let hora_termino = UserRequestedEndTime.split("T")[1].split(":")[0] + ":" + UserRequestedEndTime.split("T")[1].split(":")[1]
                let dict = changeDonatedTimeZone(actual_time_zone, fecha_inicio, hora_inicio, hora_termino)


                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                {moment(dict.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                            </div>
                            <div>
                                {dict.startTime + " - " + dict.endTime}
                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_3, description: "Recibida por", field: "UserRequestedId",
            flex: 1, minWidth: 200,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let user_id = row.UserRequestedId
                let nombre = row.UserRequestedName.split(" ")[0]
                let apellido = row.UserRequestedLastName.split(" ")[0]
                let url_foto = row.UserRequestedProfilePicture
                let roles = row.UserRequestedRoles
                return (
                    <div className='d-flex flex justify-content-center align-items-center w-100'>
                        {usuario_html(user_id, nombre, apellido, url_foto, roles)}
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_4, description: "Tipo", field: "MeetingTypeName", flex: 2, minWidth: 230,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                // let MeetingTypeName = row.MeetingTypeName
                return (
                    <div className='contenedor-tipo-solicitud'>
                        {/* <p className='tipo-solicitud'>{MeetingTypeName}</p> */}
                        <p className='tipo-solicitud'>{textMeetings.type_meetings[row.MeetingTypeId]}</p>
                        
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_5, description: "Estado", field: "RequestStatusId", flex: 1, minWidth: 180,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let RequestStatusId = row.RequestStatusId
                let request_status = textMeetings.alerta_1
                let request_status_html_class = ""
                if (RequestStatusId == 2) {
                    request_status = textMeetings.alerta_2
                    request_status_html_class = "text-success"
                }
                if (RequestStatusId == 1) {
                    request_status = textMeetings.alerta_3
                    request_status_html_class = "text-danger"
                }
                if (RequestStatusId == 3) {
                    request_status = textMeetings.alerta_4
                    request_status_html_class = "text-danger"
                }
                let request_status_html = <div className={request_status_html_class}>
                    {request_status}
                </div>
                return (request_status_html)
            }
        },
        {
            headerName: textMeetings.table_1.header_6, description: "Opciones", field: "Opciones_html", sortable: false, flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let id = row.id
                let RequestStatusId = row.RequestStatusId
                return (
                    <>
                        {opciones_html(id, row, RequestStatusId)}
                    </>
                )
            }
        },
    ]

    let table_html = <></>
    if (isLoading) {
        table_html = <div className='table-container-loading'>
            <p>{textMeetings.loading_table}</p>
        </div>
    } else {
        table_html = <div className='table-container'>
            <Tabla columnas={columnas} datos={datos}></Tabla>
        </div>
    }
    return (
        <div className='row'>
            <div className='col-12 mb-4'>
                <div className='d-flex justify-content-start align-items-center mb-2'>
                    <h3 className='mb-0 mr-2 font-italic'><b>{textMeetings.titulo_1}</b></h3>
                    <div className="btn-refresh mt-1" onClick={handleReload}>
                        <AutorenewIcon fontSize='small' />
                    </div>
                </div>
                <p>
                    {textMeetings.desc_1}
                </p>
            </div>
            <div className='col-12 mb-4'>

                <div className='table-container'>
                    {table_html}
                    
                </div>
            </div>
            {cancelConfirmationDialog}
        </div>
    )
     
}

export default MeetingsSolicitudesRecibidas;

