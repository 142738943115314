//import React, {useState} from 'react';
import './CardUser.scss'
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Card from "../Card/Card"
import { capitalizeFirstLetter, getCharLimit } from '../../utils/utilsText';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Avatar, Rating, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useGetUserDonatedTimeMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { useSelector } from 'react-redux';
import { DialogTiempoReunion } from '../../pages/UserProfileExternal/DialogTiempoReunion';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';


function CardUser({ user_evaluation_stars, CompanySponsor, handleClickOpenDialogPublicProfile, Name, LastName, Description, ProfilePicture, CompanyName, CompanyJob, CompanyPicture, UserId, CompanyPosition, PercentageSim, contact_section }: any) {
	 
	const navigate = useNavigate()
	const [isSaved, setIsSaved] = useState(false)
	let image_url = (ProfilePicture) ? ProfilePicture : "/media/iconos/icono-persona.svg"
	let company_img = (CompanyPicture) ? CompanyPicture : "/media/iconos/icono-empresa.svg"

	// se podria ñaadir algo comoe sto para modificar la imagen en contact_section, pero buscar una mejor image:
	// let company_img = (CompanyPicture) ? CompanyPicture : (contact_section ? "/media/iconos/icono-persona.svg" : "/media/iconos/icono-empresa.svg");
	let detail_url = "/user-profile/" + UserId

	let nombre_corto = Name.split(" ")[0] + " " + LastName.split(" ")[0]
		// nuevo
		const [isLoadingButton, setIsLoadingButton] = useState(true)
		const [valueDonatedTime, setValueDonatedTime] = useState<any>(false)
		const [initialChargeLoadingButton, setInitialChargeLoadingButton] = useState(true)
		const [get]: any = useGetUserDonatedTimeMutation()

		const textPublicProfile = useSelector(selectCurrentDictonary).public_profile
	
		const fetchDonatedTime = async () => {
			setIsLoadingButton(true)
			const res: any = await get({ user_id: UserId, type:  0});
			const resData = JSON.parse(res.data.responseData)
			setValueDonatedTime(resData)
			setIsLoadingButton(false)
			setInitialChargeLoadingButton(false)
		}
	
		useEffect(() => {
			 
			if (initialChargeLoadingButton) {
				// console.log("FETCHING EFFECT")
				fetchDonatedTime()
			}
		}, [valueDonatedTime]); 
		// fin nuevo
	
	function handleOnClickFav() {
		setIsSaved(!isSaved)
	}
	let fav_icon = <StarBorderOutlinedIcon className="user-save-button " onClick={handleOnClickFav}></StarBorderOutlinedIcon>
	if (isSaved) {
		fav_icon = <StarOutlinedIcon className="user-save-button user-saved" onClick={handleOnClickFav}></StarOutlinedIcon>
	}
	
	function handleOnClickUserCard() {
		handleClickOpenDialogPublicProfile()
		//navigate("../user-profile/"+UserId)
	}

	let company_sponsor_html = <></>
	if (CompanySponsor == 1) {
		company_sponsor_html = <p className='name-company-sponsor-usuario text-center'>sponsor</p>
	}


	let desc_html = textPublicProfile.sin_descripcion
	if (Description) {
		desc_html = getCharLimit(Description + "", 150)
	}

	let evaluacion_html = <></>
	if (user_evaluation_stars) {
		evaluacion_html = <div className='d-flex justify-content-center justify-content-xl-start mb-3 mb-xl-0 '>
			<Rating name="read-only" size="small" value={user_evaluation_stars} readOnly />
		</div>
	} else {
		evaluacion_html = <div className='d-flex justify-content-center justify-content-xl-start mb-3 mb-xl-0 '>
			<Rating name="read-only" size="small" value={0} readOnly />
		</div>

	}

    /* -------------- DIALOGOS SOLICITAR TIEMPO Y REUNION ---------------------------------------------------------------------- */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [sol_reu_open, set_sol_reu_open] = useState(false)
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open)
    }
	/* BOTON SOLICITUD DE REUNION O DONAR TIEMPO */
	let btn_solicitar_reunion_html = <></>
	

    if (!isLoadingButton) {
		 
		if (valueDonatedTime && valueDonatedTime.length > 0) {
			 
            btn_solicitar_reunion_html = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn btn-solicitar-reunion-card-home-exp' onClick={handleClickDialog}>{textPublicProfile.btn_solicitar_reunion}</button> {/* BTN UX -textPublicProfile.btn_solicitar_reunion texto del diccionario, de referencia crear nueva key*/}
			</>
        } else {
            btn_solicitar_reunion_html  = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn btn-solicitar-reunion-card-home-exp-2' onClick={handleClickDialog}>{textPublicProfile.btn_solicitar_tiempo}</button> {/*textPublicProfile.btn_solicitar_tiempo texto del diccionario, de referencia crear nueva key*/}
			</>
        }
	
	} else {
		btn_solicitar_reunion_html = <>Cargando...</>
	}


	
	return (
		<Card className='card-usuario'>
			<div className='d-flex flex-column flex-xl-row justify-content-center justify-content-xl-start align-items-center p-2 p-md-0'>
				<div className="avatar-usuario-container mr-2 mb-3 mb-xl-0">
					<Avatar className="avatar-usuario" alt={Name} src={image_url} ></Avatar>
					{!contact_section && (
						<Tooltip arrow className="avatar-usuario-match-percentage" title="Porcentaje de match">
							<p>{Math.floor(PercentageSim)}%</p>
						</Tooltip>
					)}
				</div>
				<div className='row no-gutters w-100 '>
					<div className='col-xl-4 col-12 h-card-user px-2'> {/* titulo, cargo y estrellas */}
						<div className='d-flex flex-column justify-content-center align-items-center align-items-xl-start  h-card-user '>
							<h5 className="card-usuario-title text-center text-xl-left font-bolder mb-1 mb-xl-0"><b>{getCharLimit(capitalizeFirstLetter(nombre_corto) + "", 60)}</b></h5>
							<p className='card-usuario-jobtitle mb-2 mb-xl-0 font-italic'>{capitalizeFirstLetter(CompanyJob)}</p>
							{evaluacion_html}
							{btn_solicitar_reunion_html}
						</div>
					</div>

					<div className='col-xl-5 col-12 h-card-user px-3 px-md-0 pr-md-3'> {/* desc */}
						<div className='d-flex justify-content-center justify-content-xl-start align-items-center  h-card-user mb-3 mb-xl-0 w-100'>
							<p className='card-usuario-desc text-center text-xl-left px-0 px-md-5 px-xl-2'>{getCharLimit(desc_html + "", 150)}</p>
						</div>
					</div>
					<div className='col-xl-3 col-12 px-2 '> {/* desc */}

						<div className='d-flex flex-column justify-content-center align-items-center h-card-user '>
							<img className="img-company-usuario mb-2" alt={CompanyName} src={company_img} ></img>
							<p className='name-company-usuario text-center'>{CompanyName}</p>
							{company_sponsor_html}
							{<button onClick={handleOnClickUserCard} className='btn btn-primary btn-ver-perfil-card-exp' >{textPublicProfile.ver_perfil}</button>}
					 
						</div>
					</div>
				</div>
			</div>
		</Card>
	)
}
export default CardUser;