import "./Landing.scss"

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectCurrentDictonary, selectCurrentLanguage } from "../../redux/features/language/LanguageSlice";

const LandingComunidad = () => {
    const lenguage = useSelector(selectCurrentLanguage)

    const infoText04 = () => {
        if (lenguage === 'es') return <>Únete a nuestra <b>comunidad</b> y sé parte del <b className="text-primary">cambio</b></>
        else return <>Schedule <b className="text-primary">FREE</b> meetings and discover networks </>
    }
    const infoText05 = () => {
        if (lenguage === 'es') return <>Porque cuando el talento se encuentra con la oportunidad, el potencial es ilimitado.</>
        else return <>Schedule <b className="text-primary">FREE</b> meetings and discover networks </>
    }
    return(
        <div className="LandingPage">
            <div className="row">                
                <div className="col-12 col-lg-6">
                    <img className="landing-inicio-foto2" src="media/new landing/comunidad.jpg" alt="" />
                </div>
                <div className="col-12 col-lg-6">
                    <div
                        className="fondo-landing-intro d-flex flex-column justify-content-center align-items-start h-100 mb-5 padding-layout-left padding-layout-right">
                        <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-titulo-2 mb-4">
                            {infoText04()}
                        </motion.h1>
                        <motion.h5 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-subtitulo-1 ">
                            {infoText05()}
                        </motion.h5>
                        <br></br>
                        <motion.ul>
                            <motion.li className="landing-inicio-lista">Conectar con expertos</motion.li>
                            <motion.li className="landing-inicio-lista">Donar horas</motion.li>
                            <motion.li className="landing-inicio-lista">Invertir</motion.li>
                            <motion.li className="landing-inicio-lista">Ser mentor</motion.li>
                            <motion.li className="landing-inicio-lista">Encontrar proyectos innovadores</motion.li>
                        </motion.ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingComunidad;