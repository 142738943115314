import React, { useState, useEffect, useRef } from 'react';
import { Box, Tab, Tabs, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import "../Meetings/Meetings.scss";
import { useGetAllActivitiesQuery, useGetUserRolesEventsQuery, useCheckUserInTicketSalesEtMtuesdayQuery, useConfirmAssistMutation } from '../../redux/features/events/eventsApiSlice';
import ActivityTableMentor from './ActivityTableMentor';
import ActivityTableMentee from './ActivityTableMentee';
import UpcomingActivitiesMentor from './UpcomingActivitiesMentor';
import UpcomingActivitiesMentee from './UpcomingActivitiesMentee';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`events-tabpanel-${index}`}
      aria-labelledby={`events-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `events-tab-${index}`,
    'aria-controls': `events-tabpanel-${index}`,
  };
}

const EventsUsers = () => {
  const user_personal_data = useSelector(selectPersonalData);
  const user_id = user_personal_data.Id;
  const navigate = useNavigate();
  const location = useLocation();
  const { idactividad } = useParams<{ idactividad?: string }>();
  const [value, setValue] = useState(0);
  const [roles, setRoles] = useState<string[]>([]);
  const [clickedButtons, setClickedButtons] = useState<string[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTicketDialog, setOpenTicketDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, message: '' });
  const [clickMessage, setClickMessage] = useState<string | null>(null);

  const mentorRefetchRef = useRef<() => void>(() => { });
  const menteeRefetchRef = useRef<() => void>(() => { });

  const { data: rolesData, isLoading: isLoadingRoles, refetch: refetchRoles } = useGetUserRolesEventsQuery(user_id, {
    refetchOnMountOrArgChange: true
  });
  const { refetch: refetchActivities } = useGetAllActivitiesQuery({});
  const { data: ticketData, isLoading: isLoadingTicket, refetch: refetchTicket } = useCheckUserInTicketSalesEtMtuesdayQuery(user_id);
  const [confirmAssist] = useConfirmAssistMutation();

  useEffect(() => {
    refetchRoles();
    refetchTicket();
  }, [location, refetchRoles, refetchTicket]);

  useEffect(() => {
    if (!isLoadingTicket) {
      if (!ticketData) {
        setOpenTicketDialog(true);
      }
    }
  }, [ticketData, isLoadingTicket]);

  useEffect(() => {
    if (!isLoadingRoles && ticketData) {
      if (rolesData && rolesData.length > 0) {
        const userRoles = rolesData.map((role: any) => role.roleName);
        setRoles(userRoles);
        setOpenDialog(false);
      } else {
        setOpenDialog(true);
      }
    }
  }, [rolesData, isLoadingRoles, ticketData]);

  useEffect(() => {
    const mode = new URLSearchParams(location.search).get('mode');
    if (idactividad && mode === 'QR') {
      confirmAssist({ userId: user_id, qrCode: idactividad })
        .then((response: any) => {
          if ('error' in response) {
            setConfirmDialog({ open: true, message: "No tienes actividades asociadas a este QR" });
          } else {
            setConfirmDialog({ open: true, message: "Actividad confirmada" });
          }
        })
        .catch((error) => {
          console.error("Error confirmando la actividad:", error);
          setConfirmDialog({ open: true, message: "Error al confirmar la actividad" });
        });
    }
  }, [idactividad, location.search, user_id, confirmAssist]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (value !== newValue) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (value === 2) {
      mentorRefetchRef.current();
    } else if (value === 3) {
      menteeRefetchRef.current();
    } else {
      refetchActivities();
    }
  }, [value, refetchActivities]);

  const handleClick = (buttonId: string, tabIndex: number) => {
      setValue(tabIndex); 
  };

  if (isLoadingRoles || isLoadingTicket) {
    return <div>Cargando...</div>;
  }

  const handleViewSchedules = (id: number) => {
    console.log(`CALENDARIO DE ROW ${id}`);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate('/settings/user-roles');
  };
  
  const handleCloseTicketDialog = () => {
    setOpenTicketDialog(false);
    navigate('/');
  };
  
  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ open: false, message: '' });
  };

// Asignación de índices según los roles
let expertButton = 0;
let emprendedorButton = 0;
let expertProximasButton = 0;
let emprendedorProximasButton = 0;

if (roles.includes('EXPERTO') && roles.includes('EMPRENDEDOR')) {
  expertButton = 0;
  emprendedorButton = 1;
  expertProximasButton = 2;
  emprendedorProximasButton = 3;
} else if (roles.includes('EXPERTO')) {
  expertButton = 0;
  expertProximasButton = 1;
} else if (roles.includes('EMPRENDEDOR')) {
  emprendedorButton = 0;
  emprendedorProximasButton = 1;
}

  return (
    <div className='meetings-container padding-layout-x'>
      {clickMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{clickMessage}</div>}
      <div className='d-block d-lg-none'>
        <div className='row no-gutters'>
          {roles.includes('EXPERTO') && (
            <div className='col-12'>
              <div
                className={`meetings-menu-item ${clickedButtons.includes('expert-inscripcion') ? 'disabled' : ''}`}
                onClick={() => handleClick('expert-inscripcion', expertButton)}
              >
                Inscripción actividades - Expert@ 
              </div>
            </div>
          )}
          {roles.includes('EMPRENDEDOR') && (
            <div className='col-12'>
              <div
                className={`meetings-menu-item ${clickedButtons.includes('emprendedor-inscripcion') ? 'disabled' : ''}`}
                onClick={() => handleClick('emprendedor-inscripcion', emprendedorButton)}
              >
                Inscripción actividades - Emprended@r 
              </div>
            </div>
          )}
          {roles.includes('EXPERTO') && (
            <div className='col-12'>
              <div
                className={`meetings-menu-item ${clickedButtons.includes('expert-proximas') ? 'disabled' : ''}`}
                onClick={() => handleClick('expert-proximas', expertProximasButton)}
              >
                Próximas actividades - Expert@ 
              </div>
            </div>
          )}
          {roles.includes('EMPRENDEDOR') && (
            <div className='col-12'>
              <div
                className={`meetings-menu-item ${clickedButtons.includes('emprendedor-proximas') ? 'disabled' : ''}`}
                onClick={() => handleClick('emprendedor-proximas', emprendedorProximasButton)}
              >
                Próximas actividades - Emprended@r 
              </div>
            </div>
          )}
        </div>
      </div>
      
      <Box sx={{ width: '100%' }}>
        <Box className="d-none d-lg-block" sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs value={value} onChange={handleChange} centered>
            {roles.includes('EXPERTO') && (
              <Tab className="meetings-menu-itemtab" label="Inscripción - Expert@" {...a11yProps(expertButton)} />
            )}
            {roles.includes('EMPRENDEDOR') && (
              <Tab className="meetings-menu-itemtab" label="Inscripción - Emprended@r" {...a11yProps(emprendedorButton)} />
            )}
            {roles.includes('EXPERTO') && (
              <Tab className="meetings-menu-itemtab" label="Próximas actividades - Expert@" {...a11yProps(expertProximasButton)} />
            )}
            {roles.includes('EMPRENDEDOR') && (
              <Tab className="meetings-menu-itemtab" label="Próximas actividades - Emprended@r" {...a11yProps(emprendedorProximasButton)} />
            )}
          </Tabs>
        </Box>

        {roles.includes('EXPERTO') && (
          <TabPanel value={value} index={expertButton}>
            <div className='col-12 mb-4'>
              <div className='d-flex justify-content-start align-items-center mb-2'>
                <h3 className='mb-0 mr-2 font-italic'><b>Inscripción actividades - Expert@s</b></h3>
                <div className="btn-refresh mt-1" onClick={() => refetchActivities()}>
                  <AutorenewIcon fontSize='small' />
                </div>
              </div>
              <p>
                Lista de actividades disponibles para expert@s.
              </p>
              <ActivityTableMentor handleViewSchedules={handleViewSchedules} />
            </div>
          </TabPanel>
        )}
        {roles.includes('EMPRENDEDOR') && (
          <TabPanel value={value} index={emprendedorButton}>
            <div className='col-12 mb-4'>
              <div className='d-flex justify-content-start align-items-center mb-2'>
                <h3 className='mb-0 mr-2 font-italic'><b>Inscripción actividades - Emprended@r</b></h3>
                <div className="btn-refresh mt-1" onClick={() => refetchActivities()}>
                  <AutorenewIcon fontSize='small' />
                </div>
              </div>
              <p>
                Lista de actividades disponibles para emprendedor@s.
              </p>
              <ActivityTableMentee handleViewSchedules={handleViewSchedules} />
            </div>
          </TabPanel>
        )}
        {roles.includes('EXPERTO') && (
          <TabPanel value={value} index={expertProximasButton}>
            <div className='col-12 mb-4'>
              <div className='d-flex justify-content-start align-items-center mb-2'>
                <h3 className='mb-0 mr-2 font-italic'><b>Próximas actividades - Expert@</b></h3>
                <div className="btn-refresh mt-1" onClick={() => mentorRefetchRef.current()}>
                  <AutorenewIcon fontSize='small' />
                </div>
              </div>
              <p>
                Lista de próximas actividades para expert@s.
              </p>
              <UpcomingActivitiesMentor onRefetch={refetch => { mentorRefetchRef.current = refetch; }} />
            </div>
          </TabPanel>
        )}
        {roles.includes('EMPRENDEDOR') && (
          <TabPanel value={value} index={emprendedorProximasButton}>
            <div className='col-12 mb-4'>
              <div className='d-flex justify-content-start align-items-center mb-2'>
                <h3 className='mb-0 mr-2 font-italic'><b>Próximas actividades - Emprended@r</b></h3>
                <div className="btn-refresh mt-1" onClick={() => menteeRefetchRef.current()}>
                  <AutorenewIcon fontSize='small' />
                </div>
              </div>
              <p>
                Lista de próximas actividades para emprendedor@s.
              </p>
              <UpcomingActivitiesMentee onRefetch={refetch => { menteeRefetchRef.current = refetch; }} />
            </div>
          </TabPanel>
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Activar un Rol</DialogTitle>
        <DialogContent>
          <p>No tienes roles activos. Por favor, activa un rol para continuar.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Ir a Configuración
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTicketDialog} onClose={handleCloseTicketDialog}>
        <DialogTitle>Ingreso eventos</DialogTitle>
        <DialogContent>
          <p>
            No estás registrado en ningún evento de Emprende Tu Mente. Ingresa a{' '}
            <a href="https://www.emprendetumente.org" target="_blank" rel="noopener noreferrer">
              www.emprendetumente.org
            </a>{' '}
            e infórmate sobre los eventos disponibles.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTicketDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDialog.open} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmación de Actividad</DialogTitle>
        <DialogContent>
          <p>{confirmDialog.message}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EventsUsers;
