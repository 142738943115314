import "./Landing.scss"

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectCurrentDictonary, selectCurrentLanguage } from "../../redux/features/language/LanguageSlice";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";

const LandingEventos = () => {
    const lenguage = useSelector(selectCurrentLanguage)

    const infoText06 = () => {
        if (lenguage === 'es') return <><b>Participa</b> en nuestros eventos y <b className="text-primary">actividades</b></>
        else return <>Schedule <b className="text-primary">FREE</b> meetings and discover networks </>
    }
    const infoText03 = () => {
        if (lenguage === 'es') return <>¡Nuevo! Agenda tu participación con anterioridad y sé parte de lo último en tendencias y ecosistema del emprendedor. Únete a nosotros y experimenta cómo las colaboraciones inesperadas generan innovación.</>
        else return <>Schedule <b className="text-primary">FREE</b> meetings and discover networks </>
    }
    return(
            <div className="row">
                <div className="col-12 col-lg-5">
                    <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-titulo-4 mb-4">
                        {infoText06()}
                    </motion.h1>
                    <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-subtitulo-3">
                        {infoText03()}
                    </motion.p>
                    <Link to="/events" className="btn-eventos"> Descubre eventos y actividades  <ArrowForwardIcon></ArrowForwardIcon> </Link>
                </div>
                <div className="col-12 col-lg-7">
                    <img className="landing-inicio-foto3" src="media/new landing/eventos.png" alt="" />
                </div>
            </div>      
    )
}
export default LandingEventos;
