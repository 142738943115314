

import "./Home.scss"
import CardUser from '../../components/CardUser/CardUser';
import CircularProgress from '@mui/material/CircularProgress';
import CardStartupHome from '../../components/CardStartupHome/CardStartupHome';
import { useSelector } from "react-redux";
import { selectCurrentDictonary } from "../../redux/features/language/LanguageSlice";
import { Link } from 'react-router-dom';



const HomeResultados = (
    {
        isLoading,
        actual_values,
        lista_resultados,
        cant_mostrada, // suma delta de cantidad mostrada
        maximo_cant_mostrada, // maxima cantidad mostrada (largo de lista de respuestas)
        handleClickMostrarMas,
        handleClickOpenDialogPublicProfile,
        contact_section
    }: any) => {
    const textHome = useSelector(selectCurrentDictonary).home

    let arr_resultados: any = []
    let btn_mostrar_mas = <></>
    let cont = 0
    if (isLoading) {
        return (
            <div className='mt-4'>
                <div className='row no-gutters '>
                    <div className='col-12'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <h6>
                                <CircularProgress className="home-loading-icon" size="3rem" />
                            </h6>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
            </div>
        )
    }
    const errorTitle = contact_section ? textHome.errorcontact_1 : textHome.error_1;
    const errorMessage = contact_section ?
    (<p className='text-center text-primary'>
         <Link to="/home" style={{ color: 'inherit', textDecoration: 'none' }}>{textHome.errorcontact_2}</Link>
    </p>)
    :
    textHome.error_2;
    if (!(lista_resultados && lista_resultados.length > 0)) {
        return (
            <div className='mt-4 mb-5'>
                <div className='mt-5 '>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <img className="home-sin-resultados mb-3" src="/media/iconos/icono-sin-resultados.svg" alt="" />
                        <h6 className='text-center mb-2'>{errorTitle}</h6>
                        <p className='text-center text-primary'>{errorMessage}</p>
                    </div>
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        )
    } else {
        lista_resultados.forEach((item: any, index: number) => {
            if (cont < cant_mostrada) {
                let resultado = <></>
                if (actual_values["tipo_busqueda"] == 0) { // emprendimientos
                    resultado = <div className='col-12' key={item.user_id || index}>
                        <CardStartupHome
                            StartupName={item.business_name}
                            StartupPicture={item.photo}
                            StartupDescription={item.description}
                            UserName={item.name}
                            UserLastName={item.last_name}
                            UserProfilePicture={item.profile_picture}
                            UserId={item.user_id}
                            PercentageSim={item.porcentaje}
                            handleClickOpenDialogPublicProfile={() => handleClickOpenDialogPublicProfile(item.user_id)}
                        />
                    </div>
                } else { // expertos
                    resultado = <div className='col-12' key={item.id || index}>
                        <CardUser
                            Name={item.name}
                            LastName={item.last_name}
                            Description={item.description}
                            ProfilePicture={item.profile_picture}
                            CompanyName={item.name_company}
                            CompanyPicture={item.picture}
                            CompanyJob={item.name_job}
                            CompanySponsor={item.sponsor}
                            UserId={item.id}
                            user_evaluation_stars={item.user_evaluation_stars}
                            PercentageSim={item.porcentaje}
                            handleClickOpenDialogPublicProfile={() => handleClickOpenDialogPublicProfile(item.id)}
                            contact_section = {contact_section}

                        />
                    </div>
                }
                arr_resultados.push(resultado)
                cont = cont + 1
            }
        });
        let show_mostrar_mas = cont < maximo_cant_mostrada
        if (show_mostrar_mas) {
            btn_mostrar_mas = <div className='d-flex justify-content-center w-100 mb-4'>
                <button className='btn btn-secondary' onClick={handleClickMostrarMas}>{textHome.btn_mostrar_mas}</button>
            </div>
        }

    }
    return (
        <div className='mt-4 mb-5'>
            <div className='row no-gutters '>
                {arr_resultados}
            </div>
            {btn_mostrar_mas}
            <br />
            <br />
            <br />
        </div>
    )
}

export default HomeResultados;


