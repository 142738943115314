import './UserProfileExternal.scss'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { personalData } from '../../interfaces/dimentions';
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import FormularioSolicitarReunion from './FormularioSolicitarReunion';
import UserProfileExternalTopCard from './UserProfileExternalTopCard';
import { useGetUserStartupsMutation } from '../../redux/features/startups/startupsApiSlice';
import { useGetUserExpertisesMutation, useGetUserLanguagesMutation, useGetUserPublicDataMutation, useGetUserRolesMutation, useGetUserTagsMutation } from '../../redux/features/user/userApiSlice';
import UserProfileExternalStartups from './UserProfileExternalStartups';
import UserProfileExternalExperiencias from './UserProfileExternalExperiencias';
import UserProfileExternalIdiomasTags from './UserProfileExternalIdiomasTags';
import BottomNav from '../../components/Navegation/BottomNav/BottomNav';
import { usePostAddUserContactMutation } from '../../redux/features/user/userApiSlice';
import GetUserDonatedTime from '../../hooks/meetings/GetUserDonatedTime/GetUserDonatedTime';
import FormularioSolicitarTiempo from './FormularioSolicitarTiempo';
import { useGetUserFeedbacksMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { DialogTiempoReunion } from './DialogTiempoReunion';
import { useNavigate,useLocation } from 'react-router-dom';
//QRMODE
import {
    DialogActions,
    Button,
} from '@mui/material';

function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
}


const UserProfileExternal = ({ user_id_param, handleClickCloseDialogPublicProfile, handleLoadContactsMain }: any) => {
    const params: any = useParams()
    const query = useQuery(); // ProfileQR
    const mode = query.get('mode');  // ProfileQR
    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile


    const navigate = useNavigate(); // Hook para navegación



    /* -------------- USUARIO ACTUAL Y EXTERNO ---------------------------------------------------------------------- */
    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    //const user_id = user_personal_data.Id; antesssss estaba asi por defecto

    const user_id = user_personal_data?.Id || 0;

    let default_user = -1
    let isExternal = 0
    let isModal = 0
    let clase_external = ""

    // QR MODE

    // console.log("UseR_id",user_id)
    useEffect(() => {
        if (mode === "QR") {
  
                setIsModalQROpen(true);
   
        } else {
            setIsModalQROpen(false);
        }
    }, [mode, user_id, navigate]);  
    const [isModalQROpen, setIsModalQROpen] = useState(false);
    
    
    const handleGenerateRequest = () => {
        // console.log("Generar solicitud");
        
       
    };
    
    if(mode == "QR"){
        // console.log("QRMode")
    }else{
        // console.log("NOQRMode")
    }
    const handleButtonClick = () => {
        handleClickDialog();  
        if (mode === "QR") {
           setIsModalQROpen(false)  
        }
    };
    // endqrmode
    if (!params.userid || params.userid == null || parseInt(params.userid) == user_id) { // no hay parametros en la ruta
        if (user_id_param && user_id_param > 0) { // perfil publico dentro de modal
            default_user = user_id_param
            isExternal = 1
            isModal = 1
            clase_external = "interno"
        } else { // perfil personal
            default_user = user_id
            isExternal = 0
            clase_external = "interno"
        }
    } else { // hay parametros en la ruta
        isExternal = 1
        isModal = 0
        clase_external = "user-profile-container-external"
        clase_external = "externo body-container"

        default_user = parseInt(params.userid)
    }
    const user_id_profile: number = default_user;

    /* -------------- SOLICITUDES DE DATOS ---------------------------------------------------------------------- */
    /* Startups */
    const [getUserStartups, { isLoadingGetUserStartupsAux }]: any = useGetUserStartupsMutation()
    const [isLoadingGetUserStartups, setIsLoadingGetUserStartups] = useState(true)
    const [user_startups, setUserStartups] = useState([])
    const fetchUserStartups = async (user_id: number) => {
        setIsLoadingGetUserStartups(true)
        try {
            const res: any = await getUserStartups({ user_id: user_id_profile });
            const resdata = JSON.parse(res.data.responseData)
            setUserStartups(resdata)
        } catch (error) {
            console.log(error)
        }
        setIsLoadingGetUserStartups(false)
    }
    // new qr//s
    const [addUserContact, { isLoading: isLoadingAddUserContact, data: dataAddUserContact, error: errorAddUserContact }] = usePostAddUserContactMutation();


    const handleAddContact = async () => {
        try {
           
            const userId = user_personal_data.Id;
            const contactUserId = user_id_profile;
            // console.log(userId, contactUserId)
            
           
            const result = await addUserContact({ userId, contactUserId }).unwrap();
           
             //console.log('Contacto añadido con éxito', result);
            setIsModalQROpen(false);  
        } catch (error) {
            
            console.error('Error al añadir contacto', error);
        }
    };
    

    //end new qr

    /* Experiencia laboral */
    const [getUserExpertises, { isLoadingGetUserExpertisesAux }]: any = useGetUserExpertisesMutation()
    const [isLoadingGetUserExpertises, setIsLoadingGetUserExpertises] = useState(true)
    const [user_expertises, setUserExpertises] = useState([])
    const fetchUserExpertises = async (user_id: number) => {
        setIsLoadingGetUserExpertises(true)
        try {
            const res: any = await getUserExpertises({ user_id: user_id_profile });
            const resdata = JSON.parse(res.data.responseData)
            setUserExpertises(resdata)
        } catch (error) {
            console.log(error)
        }
        setIsLoadingGetUserExpertises(false)
    }

    /* public data */
    const [getUserPublicData, { isLoadingGetUserPublicDataAux }]: any = useGetUserPublicDataMutation()
    const [isLoadingGetUserPublicData, setIsLoadingGetUserPublicData] = useState(true)
    const [user_public_data, setUserPublicData] = useState<any>({})
    const fetchUserPublicData = async (user_id: number) => {
        setIsLoadingGetUserPublicData(true)
        try {
            const res: any = await getUserPublicData({ user_id: user_id_profile });
            const resdata = JSON.parse(res.data.responseData)
            setUserPublicData(resdata)
        } catch (error) {
            console.log(error)
        }
        setIsLoadingGetUserPublicData(false)
    }

    /* Tags */
    const [getUserTags, { isLoadingGetUserTagsAux }]: any = useGetUserTagsMutation()
    const [isLoadingGetUserTags, setIsLoadingGetUserTags] = useState(true)
    const [user_tags, setUserTags] = useState<any>({})
    const fetchUserTags = async (user_id: number) => {
        setIsLoadingGetUserTags(true)
        try {
            const res: any = await getUserTags({ user_id: user_id_profile });
            const resdata = JSON.parse(res.data.responseData)
            setUserTags(resdata)
        } catch (error) {
            console.log(error)
        }
        setIsLoadingGetUserTags(false)
    }

    /* Tags */
    const [getUserLanguages, { isLoadingGetUserLanguagesAux }]: any = useGetUserLanguagesMutation()
    const [isLoadingGetUserLanguages, setIsLoadingGetUserLanguages] = useState(true)
    const [user_languages, setUserLanguages] = useState<any>({})
    const fetchUserLanguages = async (user_id: number) => {
        setIsLoadingGetUserLanguages(true)
        try {
            const res: any = await getUserLanguages({ user_id: user_id_profile });
            const resdata = JSON.parse(res.data.responseData)
            setUserLanguages(resdata)
        } catch (error) {
            console.log(error)
        }
        setIsLoadingGetUserLanguages(false)
    }

    /* Roles */
    const [getUserRoles, { isLoadingGetUserRolesAux }]: any = useGetUserRolesMutation()
    const [isLoadingGetUserRoles, setIsLoadingGetUserRoles] = useState(true)
    const [user_roles, setUserRoles] = useState<any>({})
    const [esEmprendedor, setEsEmprendedor] = useState<any>(false)
    const [esExperto, setEsExperto] = useState<any>(false)
    const fetchRoles = async (user_id: number) => {
        setIsLoadingGetUserRoles(true)
        const res: any = await getUserRoles({ user_id: user_id });
        const resdata = JSON.parse(res.data.responseData)
        let arr: any = []
        resdata.forEach((element: any) => {
            if (!resdata.some((x: any) => (x.id === element.RolId && element.Active == 1))) {
                if (element.RolId == 1 && element.Active == 1) {
                    setEsEmprendedor(true)
                    arr.push({ name: "Emprendedor", id: element.RolId })
                }
                if (element.RolId == 6 && element.Active == 1) {
                    setEsExperto(true)
                    arr.push({ name: "Experto", id: element.RolId })
                }
            }
        });
        setUserRoles(arr)
        setIsLoadingGetUserRoles(false)
    }

    /* Feedbacks */
    const [getUserFeedbacks, { isLoadingGetUserFeedbacksAux }]: any = useGetUserFeedbacksMutation()
    const [isLoadingGetUserFeedbacks, setIsLoadingGetUserFeedbacks] = useState(true)
    const [user_feedbacks, setUserFeedbacks] = useState<any>({})
    const [user_feedbacks_prom_estrellas, setUserFeedbacksPromEstrellas] = useState<any>(null)
    const fetchUserFeedbacks = async (user_id: number) => {
        setIsLoadingGetUserFeedbacks(true)
        setUserFeedbacksPromEstrellas(null)
        try {
            const res: any = await getUserFeedbacks({ user_id: user_id_profile });
            const resdata: any = JSON.parse(res.data.responseData)
            if (resdata && resdata.length > 0) {
                let suma = 0
                let cont_n = 0

                resdata.forEach((element: any) => {
                    cont_n = cont_n + 1
                    suma = suma + element.UserEvaluationStars
                });
                setUserFeedbacksPromEstrellas(suma / cont_n)
            }

            setUserFeedbacks(resdata)
        } catch (error) {
            console.log(error)
        }
        setIsLoadingGetUserFeedbacks(false)
    }

    /* -------------- FIN SOLICITUDES DE DATOS ---------------------------------------------------------------------- */


    /* -------------- USEEFFECT ---------------------------------------------------------------------- */
    useEffect(() => {
        fetchUserStartups(user_id_profile).catch(console.error);
        fetchUserExpertises(user_id_profile).catch(console.error);
        fetchUserPublicData(user_id_profile).catch(console.error);
        fetchUserTags(user_id_profile).catch(console.error);
        fetchUserLanguages(user_id_profile).catch(console.error);
        fetchRoles(user_id_profile).catch(console.error);
        fetchUserFeedbacks(user_id_profile).catch(console.error);
    }, [params]); // antes estaba vacio,es decir solo con [] para cuando recargue la pag. ahora se agrega params para que cada vez que cambiemos le user id de la página se obtenga la info nuevmanete
    /* -------------- FIN USEEFFECT ---------------------------------------------------------------------- */

    //USSEEFFECT PARA REACCIONAR A CAMBIOS EN LA RUTA (NOTIFICACIONES)
    

    /* ROLES */
    let startups_html = <></>
    if (esEmprendedor) {
        startups_html = <div className='col-12'>
            <UserProfileExternalStartups user_startups={user_startups} is_loading_user_startups={isLoadingGetUserStartups}></UserProfileExternalStartups>
        </div>
    }

    let expert_html = <></>
    if (esExperto) {
        expert_html = <div className='col-12'>
            <UserProfileExternalExperiencias user_expertises={user_expertises} is_loading_user_expertises={isLoadingGetUserExpertises}></UserProfileExternalExperiencias>
        </div>
    }

    function public_profile_html() {
        let public_profile_html_el = <></>
        if (isLoadingGetUserStartups || isLoadingGetUserExpertises || isLoadingGetUserPublicData || isLoadingGetUserTags || isLoadingGetUserLanguages || isLoadingGetUserRoles || isLoadingGetUserFeedbacks) {
            let fullscreenClass = ""
            if (isModal == 0) {
                fullscreenClass = "fullscreen"
            }
            public_profile_html_el = <div className={'loading-backdrop ' + fullscreenClass}>
                <h2>
                    <i className="fas fa-spinner fa-spin mr-4"></i>
                    <h2 className='d-inline'>{textPublicProfile.label_loading}</h2>
                </h2>
            </div>
        } else {
            public_profile_html_el = <div className={'user-profile-container ' + clase_external}>
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <UserProfileExternalTopCard
                            user_feedbacks_prom_estrellas={user_feedbacks_prom_estrellas}
                            user_id_profile={user_id_profile}
                            esEmprendedor={esEmprendedor}
                            esExperto={esExperto}
                            user_expertises={user_expertises}
                            user_donated_times={user_donated_times}
                            isModal={isModal}
                            handleClickCloseDialogPublicProfile={handleClickCloseDialogPublicProfile}
                            user_public_data={user_public_data}
                            is_loading_public_data={isLoadingGetUserPublicData}
                            handleClickDialog={handleClickDialog}
                            isExternal={isExternal}
                            handleAddContact={handleAddContact}
                            handleLoadContactsMain={handleLoadContactsMain}
                            currentUrl={window.location.href}
                            isQRMode={mode === "QR"}  
                        ></UserProfileExternalTopCard>
                    </div>
                    <div className='col-12'>
                        <UserProfileExternalIdiomasTags user_languages={user_languages} is_loading_user_startups={isLoadingGetUserStartups} user_tags={user_tags} ></UserProfileExternalIdiomasTags>
                    </div>

                </div>
                <div className='row no-gutters user-profile-startups-experiences-container'>
                    {startups_html}
                    {expert_html}

                </div>
            </div>
        }
        return (public_profile_html_el)
    }

    /* -------------- TIEMPO DONADO DEL PERFIL ---------------------------------------------------------------------- */
    let GetUserDonatedTime_aux = GetUserDonatedTime(user_id_profile);
    let user_donated_times = GetUserDonatedTime_aux.value
    // console.log(user_donated_times)




    /* -------------- DIALOGOS SOLICITAR TIEMPO Y REUNION ---------------------------------------------------------------------- */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [sol_reu_open, set_sol_reu_open] = useState(false)
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open)
    }

    // let solicitar_reunion_dialog = <></>
    // if (user_donated_times && user_donated_times.length > 0) {
    //     solicitar_reunion_dialog = <Dialog
    //         fullWidth
    //         fullScreen={fullScreen}
    //         maxWidth="lg"
    //         open={sol_reu_open}
    //         onClose={handleClickDialog}
    //     >
    //         <DialogTitle >
    //             {textPublicProfile.btn_solicitar_reunion}
    //         </DialogTitle>
    //         <DialogContent>
    //             <FormularioSolicitarReunion user_donated_times={user_donated_times} user_id_profile={user_id_profile} handleOnClose={handleClickDialog}></FormularioSolicitarReunion>
    //         </DialogContent>
    //     </Dialog>
    // } else {
    //     solicitar_reunion_dialog = <Dialog
    //         fullWidth
    //         /* fullScreen={fullScreen} */
    //         maxWidth="md"
    //         open={sol_reu_open}
    //         onClose={handleClickDialog}
    //     >
    //         <DialogTitle >
    //             {textPublicProfile.btn_solicitar_reunion}
    //         </DialogTitle>
    //         <DialogContent>
    //             <FormularioSolicitarTiempo solicitudes_tiempo_usuario={[]} user_id_profile={user_id_profile} handleOnClose={handleClickDialog}></FormularioSolicitarTiempo>
    //         </DialogContent>
    //     </Dialog>

    // }
    /* -------------- FIN DIALOGOS SOLICITAR TIEMPO Y REUNION ---------------------------------------------------------------------- */

    let navegation_html = <></>
    if (isModal == 0) {
        navegation_html = <>
            <BottomNav></BottomNav>
        </>
    }

    /* -------------- RETURN ---------------------------------------------------------------------- */

    return (
        <>
            {navegation_html}
            {public_profile_html()}
            <DialogTiempoReunion
                user_id_profile={user_id_profile} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile} 
                QR={mode == "QR"}
            />
           
            {/* {solicitar_reunion_dialog} */}

        </>
    )
    /* -------------- FIN RETURN ---------------------------------------------------------------------- */

}

export default UserProfileExternal;