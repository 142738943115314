//import { useParams } from 'react-router-dom'


import Card from '../../../components/Card/Card'


const Language = () => {
    //const params = useParams()

    return (
        <>
                <Card className="w-100">
                    <div className='row'>
                        <div className="col-md-12">
                            <h3>Language</h3>
                        </div>
                        <div className="col-md-12">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
                            <label className="form-check-label" htmlFor="exampleRadios1">
                                Español / Spanish
                            </label>
                            </div>
                            <div className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
                            <label className="form-check-label" htmlFor="exampleRadios2">
                                Inglés / English
                            </label>
                            </div>
                            <a href="#" className='btn btn-success'>Guardar Cambios</a>
                        </div>
                    </div>
                </Card>
        </>
    )
}

export default Language;