import "./Landing.scss"

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectCurrentDictonary, selectCurrentLanguage } from "../../redux/features/language/LanguageSlice";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";

const LandingInicio = () => {

    const lenguage = useSelector(selectCurrentLanguage)
    const textLanding = useSelector(selectCurrentDictonary).landing

    const infoText = () => {
        if (lenguage === 'es') return (
            <>Las oportunidades se <b>multiplican</b> cuando el talento se<b> encuentra.</b></>
        )
        else return <>We generate <b className="text-primary">opportunities</b> by <b className="font-italic"> connecting</b> the <b className="text-primary">entrepreneurship </b> ecosystem</>
    }

    const infoText2 = () => {
        if (lenguage === 'es') return <b> EtMmeet es el punto de encuentro ideal</b>
        else return <>Schedule <b className="text-primary">FREE</b> meetings and discover networks </>
    }
    const infoText03 = () => {
        if (lenguage === 'es') return <>Aquí, conectamos pares improbables, fomentando colaboraciones inesperadas que impulsan la innovación y el crecimiento</>
        else return <>Schedule <b className="text-primary">FREE</b> meetings and discover networks </>
    }
    
    

    return (
        <div className='landing-page '>
            {/*<img className="fondo-landing-1-bg-img " src="media/new landing/img_home.png" alt="" />*/}
            <div className="row">
                <div className="col-12 col-lg-5">
                    <div
                        className="fondo-landing-intro d-flex flex-column justify-content-center align-items-start h-100 mb-5 padding-layout-left padding-layout-right">
                        <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-titulo-1 mb-4">
                            {infoText()}
                        </motion.h1>
                        <motion.h5 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-subtitulo-1 ">
                            {infoText2()}
                        </motion.h5>
                        <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-subtitulo-2 ">
                            {infoText03()}
                        </motion.p>
                        <Link to="/home" className="btn-agenda"> Agenda una reunión  <ArrowForwardIcon></ArrowForwardIcon> </Link>
                        

                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <img className="landing-inicio-foto1" src="media/new landing/landing1.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default LandingInicio;