import "./Landing.scss"

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectCurrentDictonary, selectCurrentLanguage } from "../../redux/features/language/LanguageSlice";
import { Link } from "react-router-dom";

const LandingPerfiles = () => {
    const lenguage = useSelector(selectCurrentLanguage)

    const infoText06 = () => {
        if (lenguage === 'es') return <>Escoge el <b className="text-primary">perfil</b> de tu <b>usuario</b></>
        else return <>Schedule <b className="text-primary">FREE</b> meetings and discover networks </>
    }
    return(
        <div className="row">
            <div className="col-12 col-lg-4">
                <motion.h1 initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} className="fondo-landing-titulo-3 mb-4">
                    {infoText06()}
                </motion.h1>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card-contenedor">
                    <div className="card-landing-new card-emprendedor">
                        <div className="card-content">
                            <h2 className="card-title">Emprendedor/a</h2>
                            <p className="card-text">Si tienes un emprendimiento aprovecha y reúnete gratis con expertos/as que quieren conectarse con emprendedores/as como tú</p>
                            <Link to="/settings/user-startups" className="card-button">Ingresa como emprendedor/a</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card-contenedor">
                    <div className="card-landing-new card-experto">
                        <div className="card-content">
                            <h2 className="card-title">Experto/a</h2>
                            <p className="card-text">Ten reuniones de 20 minutos 1 a 1 con emprededores/as innovadores/as, previamente seleccionados, que quieren reunirse contigo.</p>
                            <Link to="/settings/user-experience" className="card-button">Ingresa como experto/a</Link>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default LandingPerfiles;