import React from 'react';
import Tabla from '../../components/Tabla/Tabla';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import QRCode from 'qrcode.react';

type Event = {
    id: number;
    event: string;
    date: string;
    type: string;
    description?: string;
    qr: string;
    visible: number;
};

interface EventTableProps {
    events: Event[];
    loading: boolean;
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleOpenDialog: () => void;
}

const EventTable: React.FC<EventTableProps> = ({ events, loading, handleEdit, handleDelete, handleOpenDialog }) => {
    const columnas: GridColDef[] = [
        {
            headerName: "Evento", field: "event", flex: 1, minWidth: 150
        },
        {
            headerName: "Fecha", field: "date", flex: 1, minWidth: 130,
            renderCell: (cellvalues: any) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Tipo", field: "type", flex: 1, minWidth: 150
        },
        {
            headerName: "Descripción", field: "description", flex: 2, minWidth: 250
        },
        {
            headerName: "QR", field: "qr", flex: 1, minWidth: 100,
            renderCell: (cellvalues: any) => {
                const isLast = cellvalues.row.id === 'add-button';
                if (isLast) return null;
                return (
                    <div className='d-flex justify-content-center'>
                        <QRCode value={cellvalues.value} size={50} />
                    </div>
                );
            }
        },
        {
            headerName: "Opciones", field: "opciones", flex: 1, minWidth: 120, maxWidth: 140,
            renderCell: (cellvalues: any) => {
                const isLast = cellvalues.row.id === 'add-button';
                if (isLast) {
                    return (
                        <div className='d-flex flex-column justify-content-center align-items-stretch w-100' style={{ paddingTop: '10px', paddingBottom: '15px' }}>
                            <button className="btn btn-primary btn-block p-1" onClick={handleOpenDialog} >Añadir</button>
                        </div>
                    );
                } else {
                    return (
                        <div className='d-flex flex-column justify-content-center align-items-stretch w-100'>
                            <button className="btn btn-secondary btn-block p-1" onClick={() => handleEdit(cellvalues.row.id)}>Modificar</button>
                            <button className="btn btn-danger btn-block p-1" onClick={() => handleDelete(cellvalues.row.id)}>Eliminar</button>
                        </div>
                    );
                }
            }
        }
    ];

    const tableData = events ? [...events, { id: 'add-button', event: '', date: '', type: '', description: '', qr: '', visible: 1 }] : [{ id: 'add-button', event: '', date: '', type: '', description: '', qr: '', visible: 1 }];

    return loading ? (
        <div className='table-container-loading'>
            <p>Cargando eventos...</p>
        </div>
    ) : (
        <div className='table-container'>
            <Tabla columnas={columnas} datos={tableData}></Tabla>
        </div>
    );
};

export default EventTable;
