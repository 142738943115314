import { useSelector } from "react-redux";
import "./Landing.scss"
import { selectCurrentDictonary } from "../../redux/features/language/LanguageSlice";
import { useNavigate } from "react-router-dom";


const LandingFooter = () => {


    const textLanding = useSelector(selectCurrentDictonary).landing

    const navigate = useNavigate();

    return (
        <div className='landing-page landing-page-footer  py-5'>
            <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center padding-layout-x">
                <div className="mb-5 mb-lg-0">
                    <h4 className="font-italic"><b>{textLanding.titulo_14}</b></h4>
                    <div>
                        <a className="text-white parrafo1 mb-2" target="_blank" href="mailto: soporte@emprendetumente.org"><i className="far fa-lg fa-envelope mr-2"></i>soporte@emprendetumente.org</a>
                    </div>
                    <div>
                        <a className="text-white parrafo1" target="_blank" href="https://wa.me/+56937610371"><i className="fab fa-lg fa-whatsapp mr-2"></i>+56937610371</a>
                    </div>
                </div>
                <div className="mb-5 mb-lg-0">
                    <a href="https://www.emprendetumente.org/" target="_blank">
                        <img className='landing-page-footer-foto' src="media/landing/etm-logo-rojo.svg" alt="" />

                    </a>
                </div>
                <div className="mb-lg-0">
                    <div className="d-flex flex-column justify-content-center ">
                        <h5>{textLanding.titulo_15}</h5>
                        <div className="d-flex justify-content-center mt-2">
                            <a className="text-white mr-4" target="_blank" href="https://www.instagram.com/emprendetumente_org/ "><i className="fab fa-2x fa-instagram "></i></a>
                            <a className="text-white mr-4" target="_blank" href="https://www.youtube.com/channel/UCifLlPpsPUVqkdubtrDKe7w"><i className="fab fa-2x fa-youtube "></i></a>
                            <a className="text-white" target="_blank" href="https://www.linkedin.com/company/emprende-tu-mente/"><i className="fab fa-2x fa-linkedin "></i></a>
                        </div>
                        <span  >version 1.2.0.0</span>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LandingFooter;