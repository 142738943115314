import React from 'react';
import { DataGrid, esES, enUS } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../../../src/redux/features/language/LanguageSlice';  // Asegúrate de que esta ruta es correcta
import './Tabla.scss';

export default function Tabla({ columnas, datos, height = 500 }: any) {
  // Obtener el idioma actual desde Redux
  const currentLanguage = useSelector(selectCurrentLanguage);

  // Verificar qué idioma regresa el selector
  //console.log(currentLanguage);

  // Determinar el localeText basado en el idioma del selector
  const localeText = currentLanguage === 'en'
    ? enUS.components.MuiDataGrid.defaultProps.localeText
    : esES.components.MuiDataGrid.defaultProps.localeText;

  return (
    <div style={{ height: height, width: '100%' }}>
      <DataGrid
        className='table'
        rows={[...datos]}
        columns={[...columnas]}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        checkboxSelection={false}
        rowSelection={false}
        localeText={localeText}
      />
    </div>
  );
}