import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import Tabla from '../../components/Tabla/Tabla';
import { useGetAllAvailableTimesQuery } from '../../redux/features/events/eventsApiSlice';

type BlockSchedule = {
    id: number;
    eventName: string;
    activityName: string;
    activityType: string;
    startTime: string;
    endTime: string;
    mentor: string;
    mentorEmail: string;
    mentorId: number | null;
    mentee: string;
    menteeEmail: string;
    menteeId: number | null;
};

interface BlockScheduleTableProps {
    handleEdit: (blockSchedule: BlockSchedule) => void;
    onFilterChange: (filter: string) => void;
    filter: string;
}

const BlockScheduleTable: React.FC<BlockScheduleTableProps> = ({ handleEdit, onFilterChange, filter }) => {
    const { data: availableTimes, isLoading } = useGetAllAvailableTimesQuery({});
    const [tableData, setTableData] = useState<BlockSchedule[]>([]);
    const [filteredData, setFilteredData] = useState<BlockSchedule[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<string>(filter);

    useEffect(() => {
        if (availableTimes) {
            setTableData(availableTimes);
            setFilteredData(filter ? availableTimes.filter((data: BlockSchedule) => data.activityName === filter) : availableTimes);
        }
    }, [availableTimes, filter]);

    const handleChangeActivity = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setSelectedActivity(value);
        setFilteredData(value ? tableData.filter((data) => data.activityName === value) : tableData);
        onFilterChange(value);
    };

    const handleEditClick = (blockSchedule: BlockSchedule) => {
        //console.log('Selected BlockSchedule ID:', blockSchedule.id); 
        handleEdit(blockSchedule);
    };

    const columnas: GridColDef[] = [
        { headerName: "ID", field: "id", flex: 0.5, minWidth: 80 },
        { headerName: "Evento", field: "eventName", flex: 1, minWidth: 150 },
        { headerName: "Actividad", field: "activityName", flex: 1, minWidth: 150 },
        { headerName: "Tipo", field: "activityType", flex: 1, minWidth: 150 },
        {
            headerName: "Inicio", field: "startTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Término", field: "endTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        { headerName: "Mentor", field: "mentor", flex: 1, minWidth: 150 },
        { headerName: "Mentee", field: "mentee", flex: 1, minWidth: 150 },
        {
            headerName: "Opciones", field: "opciones", flex: 1, minWidth: 120, maxWidth: 150,
            renderCell: (cellvalues) => (
                <div className='d-flex flex-column justify-content-center align-items-stretch w-100'>
                    <button className="btn btn-secondary btn-block p-1" onClick={() => handleEditClick(cellvalues.row)}>Modificar</button>
                </div>
            )
        }
    ];

    return isLoading ? (
        <div className='table-container-loading'>
            <p>Cargando horarios disponibles...</p>
        </div>
    ) : (
        <div className='table-container'>
            <Box sx={{ marginBottom: 2 }}>
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedActivity}
                        onChange={handleChangeActivity}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Seleccionar actividad' : (value as string)}
                        inputProps={{ 'aria-label': 'Seleccionar actividad' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todas las actividades</em>
                        </MenuItem>
                        {tableData && Array.from(new Set(tableData.map((time) => time.activityName))).map((activityName, index) => (
                            <MenuItem key={index} value={activityName}>{activityName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Tabla columnas={columnas} datos={filteredData} getRowId={(row: BlockSchedule) => row.id}></Tabla>
        </div>
    );
};

export default BlockScheduleTable;
