import { createSlice } from "@reduxjs/toolkit"

import { store } from '../../store'

interface alerts {
    id: string
    type: string
    title: string
    desc?: string
    timeout?: number
    close?: boolean
}
export interface initialStateAlerts {
    alerts_list: alerts[]
}

/* { id: 1, type: "success", title: "titulo", desc: "desc", timeout: 1 },
{ id: 2, type: "loading", title: "Cargando...", desc: "Inicio de sesión", timeout: 1 },
{ id: 3, type: "warning", title: "titulo", desc: "desc", timeout: 1 }, */
const initialState: initialStateAlerts = {
    alerts_list: [
        /* { id: 1, type: "success", title: "titulo", desc: "desc", timeout: 1 }, */
    ]
}

const alertsSlice = createSlice({
    name: 'alerts',
    initialState: initialState,
    reducers: {
        setAlertsList: (state, action) => {
            const { alerts_list } = action.payload
            state.alerts_list = alerts_list
        },
        addAlert: (state, action) => {
            const { alert } = action.payload

            let new_id: string = ""
            if (!alert.id) {
                let cont = 0
                let first = 0
                for (var i = 0; i < state.alerts_list.length; i++) {
                    if (parseInt(state.alerts_list[i]["id"])) {
                        if (cont == 0) {
                            first = i
                        }
                        cont += 1
                    }
                }
                if (cont > 0) {
                    var max = state.alerts_list[first]
                    for (var i = 0; i < state.alerts_list.length; i++) {
                        if (parseInt(state.alerts_list[i]["id"]) && parseInt(state.alerts_list[i]["id"]) > parseInt(max["id"]))
                            max = state.alerts_list[i];
                    }
                    new_id = (parseInt(max["id"]) + 1).toString()
                } else {
                    new_id = "1"
                }
                let aux_alert: alerts = { ...alert, id: new_id }
                state.alerts_list = [...state.alerts_list, aux_alert]
            } else {
                let aux_alert: alerts = { ...alert, id: alert.id }
                state.alerts_list = [...state.alerts_list, aux_alert]
            }

            if (alert.timeout) {
                setTimeout(() => {
                    store.dispatch(deleteAlert({ alert_id: new_id }))
                }, alert.timeout)
            }

        },
        deleteAlert: (state, action) => {
            const { alert_id } = action.payload

            let alerts_list_aux = [...state.alerts_list].filter((obj: any) => alert_id !== obj.id);
            state.alerts_list = alerts_list_aux
        },
    },
})

export const { setAlertsList, addAlert, deleteAlert } = alertsSlice.actions

export default alertsSlice.reducer

export const selectAlertsList = (state: any) => state.alerts.alerts_list