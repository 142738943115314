

/* Iconos */
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { useEffect, useLayoutEffect, useState } from 'react';
import Switch from '@mui/material/Switch';

import { useGetUsersAvailableTimesMutation } from '../../redux/features/meetings/meetingsApiSlice';



const FiltrosHomeAcciones = ({ handleClickFiltrar, handleClickGuardar, handleClickLimpiar, handleClickRestablecer, isLoadingAcciones, lightBg = false, lista_resultados, set_lista_resultados, handleClickFiltrarMeetings, esPrimerClick, setEsPrimerClick, mobileversion}: any) => {
    const [contador, setContador] = useState(0);
    const [cargandoFiltro, setCargandoFiltro] = useState(false);
    const [get]: any = useGetUsersAvailableTimesMutation()
    const textMeetings = useSelector(selectCurrentDictonary).meetings
    
    
    const toggleFiltrar = () => {
        if (esPrimerClick) {
            handleClickFiltrarMeetings();
        } else {
            handleClickFiltrar();
        }
        setEsPrimerClick(!esPrimerClick);
    };
    let baseClass = "btn btn-rounded btn-block btn-sm d-flex justify-content-center align-items-center mt-2";
    let classFiltrarMeetings = esPrimerClick ? `${baseClass} btn-outline-white` : `${baseClass} btn-success`;
    if (lightBg && !esPrimerClick) {
        classFiltrarMeetings = `${baseClass} btn-success`;
    }
    const fetchDonatedTime = async () => {

        const res: any = await get({});
        // console.log("sad")
        const resData = JSON.parse(res.data.responseData)
        // console.log("RESDATA", resData)
        return resData
    }

    // async function filtrarReuniones() {
    //     // console.log("LISTA RESULTADOS V2 FROM FUNC", lista_resultados);

    //     //const idsToFilter = await fetchDonatedTime()
    //     const idsToFilter = [
    //         14527, 18270, 19057, 19075
    //     ];

    //     console.log("idsto filter", idsToFilter)
    //     set_lista_resultados((prevState: any[]) => prevState.filter(obj => idsToFilter.includes(obj.id)));


    //     console.log(lista_resultados);
    // }
    useEffect(() => {
        // console.log("Lista de resultados actualizada: ", lista_resultados);
    }, [lista_resultados]);
    


    const textHome = useSelector(selectCurrentDictonary).home

    let class_buscar = "btn btn-primary btn-rounded btn-block btn-sm d-flex justify-content-center align-items-center"
    let class_limpiar = "btn btn-outline-white btn-rounded btn-block btn-sm d-flex justify-content-center align-items-center mt-2"
    let class_guardar = "btn btn-primary btn-rounded btn-block btn-sm d-flex justify-content-center align-items-center mt-2"
    let class_restablecer = "btn btn-outline-white btn-rounded btn-block  btn-sm d-flex justify-content-center align-items-center mt-2"

    if (lightBg) {
        class_limpiar = "btn btn-outline-dark btn-rounded btn-block btn-sm d-flex justify-content-center align-items-center mt-2"
        class_restablecer = "btn btn-outline-dark btn-rounded btn-block  btn-sm d-flex justify-content-center align-items-center mt-2"
    }

    //  useEffect(() => { 
    //         handleClickFiltrar(); 
    // }, []);

    // useEffect(() => {
    //     if(isLoadingAcciones==true){
    //         handleClickFiltrar();}
    // }, [isLoadingAcciones]);
    const switchContainerStyle = {
        display: 'flex',
       
        alignItems: 'center',   
        justifyContent: 'center',
        marginTop: '8px',  
      
        minHeight: 'calc(0.6rem + 1.6rem)',  
      };
      
      const labelStyle = {
        fontWeight: 800,  
        color: 'white',  
        marginRight: '10px',
        marginBottom: '5px',
        fontSize: '0.92rem'
      };
      

    return (
        <div className="row no-gutters mb-2">
            <div className="col-12 ">
                <button onClick={() => {
                    handleClickFiltrar();
                    setEsPrimerClick(true);
                }} disabled={isLoadingAcciones} className={class_buscar}>
                    <FilterAltOutlinedIcon />
                    <p className='ml-1'><b>{textHome.btn_filtrar}</b></p>
                </button>
            </div>
            <div className="col-12  ">
                <button onClick={() => handleClickLimpiar()} disabled={isLoadingAcciones} className={class_limpiar}>
                    <FilterAltOffOutlinedIcon />
                    <p className='ml-1'><b>{textHome.btn_limpiar}</b></p>
                </button>
            </div>
            {!mobileversion && ( // Renderizar solo si no es versionmobil
                <div className="col-12" style={switchContainerStyle}>
                    <Switch
                        checked={!esPrimerClick}
                        onChange={toggleFiltrar}
                        name="filtroSwitch"
                        color="primary"
                    />
                    <label style={labelStyle}>
                        {textMeetings.btn_tiempo_donado}
                    </label>
                </div>
            )}
            <div className="col-6 pr-2">
                <button onClick={() => handleClickGuardar()} disabled={isLoadingAcciones} className={class_guardar}>
                    <div><AddOutlinedIcon></AddOutlinedIcon></div>
                    <p className='ml-1'><b>{textHome.btn_guardar}</b></p>
                </button>
            </div>
            <div className="col-6 pl-2">
                <button onClick={() => handleClickRestablecer()} disabled={isLoadingAcciones} className={class_restablecer}>
                    <RefreshIcon />
                    <p className='ml-1'><b>{textHome.btn_restablecer}</b></p>
                </button>
            </div>
        </div>
    )

}

export default FiltrosHomeAcciones;
