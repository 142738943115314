import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';

const adminIds = [19044, 19131, 1469, 20003];

const EmailSender = () => {
  const [subject, setSubject] = useState('');  
  const [body, setBody] = useState('');  
  const [recipient, setRecipient] = useState('');  

  const navigate = useNavigate();
  const userPersonalData = useSelector(selectPersonalData);

  useEffect(() => {
    if (!adminIds.includes(userPersonalData.Id)) {
      navigate('/home');
    }
  }, [userPersonalData, navigate]);

  if (!adminIds.includes(userPersonalData.Id)) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Envío correo
      </Typography>
      <TextField
        label="Título del Correo"
        variant="outlined"
        fullWidth
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Cuerpo del Correo"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={body}
        onChange={(e) => setBody(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Destinatario"
        variant="outlined"
        fullWidth
        value={recipient}
        onChange={(e) => setRecipient(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary">
        Enviar
      </Button>
    </Box>
  );
};

export default EmailSender;
