
import React, { useEffect } from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import MeetingsDonarTiempo from './MeetingsDonarTiempo';

import Tabla from '../../../components/Tabla/Tabla';

import "../Meetings.scss"

import { GridColDef } from '@mui/x-data-grid';
import { TiempoDonado } from "../../../interfaces/meetings"
import { useDispatch } from 'react-redux';
import { personalData } from '../../../interfaces/dimentions';
import GetUserDonatedTime from '../../../hooks/meetings/GetUserDonatedTime/GetUserDonatedTime';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../../redux/features/user/userSlice';
import { useGetUserDonatedTimeMutation, usePostDeleteUserDonatedTimeMutation, useDeleteZoomMeetingMutation } from '../../../redux/features/meetings/meetingsApiSlice';
import { setUserDonatedTimes } from '../../../redux/features/meetings/meetingsSlice';

import GetActualTimeZone from '../../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import { changeDonatedTimeZone } from '../../../utils/utilsForms';

import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';

const MeetingsTiempoDonado = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch()
    const textMeetings = useSelector(selectCurrentDictonary).meetings

    const actual_time_zone = GetActualTimeZone()

    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let user_id = user_personal_data.Id
    const [getUserDonatedTime, { isLoadingGetUserDonatedTime }]: any = useGetUserDonatedTimeMutation()
    const [postDeleteUserDonatedTime, { isLoadingPostDeleteUserDonatedTime }]: any = usePostDeleteUserDonatedTimeMutation()
    let GetUserDonatedTime_aux = GetUserDonatedTime(user_id);
    let user_donated_times = GetUserDonatedTime_aux.value

    const [openDonarTiempo, setOpenDonarTiempo] = React.useState(false);


    /* DONAR TIEMPO */
    const handleClickOpenDonarTiempo = () => {
        setOpenDonarTiempo(true);
    };

    const handleCloseDonarTiempo = () => {
        setOpenDonarTiempo(false);
    };

    /* TABLA TIEMPOS DONADOS */
    let columnas: GridColDef[] = [
        {
            headerName: textMeetings.th_fecha, description: textMeetings.th_fecha, field: "Date",
            flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let dict = changeDonatedTimeZone(actual_time_zone, row.Date, row.StartTime, row.EndTime)
                return (dict.date)
            }
        },
        {
            headerName: textMeetings.th_hora_inicio, description: textMeetings.th_hora_inicio, field: "StartTime",
            flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let dict = changeDonatedTimeZone(actual_time_zone, row.Date, row.StartTime, row.EndTime)
                return (dict.startTime)
            }
        },
        {
            headerName: textMeetings.th_hora_termino, description: textMeetings.th_hora_termino, field: "EndTime",
            flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let dict = changeDonatedTimeZone(actual_time_zone, row.Date, row.StartTime, row.EndTime)
                return (dict.endTime)
            }
        },

        {
            headerName: textMeetings.th_opciones, description: textMeetings.th_opciones, field: "Opciones_html", sortable: false, flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                return (
                    <>
                        {opciones_html(row.id)}
                    </>
                )
            }
        }
    ]


    let datos: TiempoDonado[] = [
    ]
    user_donated_times?.map((item: any) => {
        datos.push({
            id: item.Id,
            Date: item.Date.split("T")[0],
            StartTime: item.StartTime.split("T")[1],
            EndTime: item.FinishTime.split("T")[1],
            TimeZoneOffset: item.TimeZoneOffset
        })
    })

    function reloadDonatedTimes() {
        const fetch = async () => {
            const res: any = await getUserDonatedTime({ user_id: user_id });
            const resData = JSON.parse(res.data.responseData)
            dispatch(setUserDonatedTimes({ user_donated_times: resData }))

        }
        fetch()
    }

    function handleClickDeleteDonatedTime(id_tiempo_donado: any) {
        const fetchSave = async () => {
            let item = {
                "id": id_tiempo_donado,
            }

            await postDeleteUserDonatedTime({ body: item });
            reloadDonatedTimes()
        }
        fetchSave().catch(console.error);
    }

    function opciones_html(id_tiempo_donado: any) {
        return (
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <button className="btn btn-danger btn-block p-1" onClick={() => handleClickDeleteDonatedTime(id_tiempo_donado)}>{textMeetings.btn_eliminar}</button>
            </div>
        )
    }

    return (
        <>
            <div className='row align-items-center justify-content-between'>
                <div className='col-12 col-lg-auto '>

                    <h3 className='mb-0 mr-2 mb-2 font-italic'><b>{textMeetings.titulo_3}</b></h3>

                    <p className='mb-1'>
                        {textMeetings.desc_3}
                    </p>
                    <p className='mb-2 text-primary'>
                        {textMeetings.desc_3_1}
                    </p>

                </div>
                <div className='col-12 col-lg-auto mb-2'>
                    <button className='btn btn-primary btn-rounded btn-lg ' onClick={handleClickOpenDonarTiempo}>
                         {textMeetings.btn_donar}
                    </button>
                </div>

            </div>
            <div className='row mt-2'>
                <div className='col-12 mb-4'>
                    {/* TABLA */}
                    <Tabla columnas={columnas} datos={datos}></Tabla>

                </div>
                <div className='col-12'>
                    {/* MODAL (DIALOG) */}
                    <Dialog
                        fullWidth
                        fullScreen={fullScreen}
                        maxWidth="md"
                        open={openDonarTiempo}
                        onClose={handleCloseDonarTiempo}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseDonarTiempo}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1, minWidth: 120 }} variant="h6" component="div">
                                    {textMeetings.btn_donar}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <div className='my-5'>
                            <MeetingsDonarTiempo reloadDonatedTimes={reloadDonatedTimes} handleCloseDonarTiempo={handleCloseDonarTiempo}></MeetingsDonarTiempo>
                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default MeetingsTiempoDonado;

