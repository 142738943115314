import React, { useState, useEffect } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import Tabla from '../../components/Tabla/Tabla';
import { useGetUpcomingActivitiesByMentorQuery, useSendActivityFeedbackMutation } from '../../redux/features/events/eventsApiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { Tooltip } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import './UpcomingActivitiesMentor.scss';
import FeedbackDialog from './FeedbackDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';

interface UpcomingActivitiesMentorProps {
    onRefetch?: (refetch: () => void) => void;
}

interface Activity {
    id: number;
    eventName: string;
    activityName: string;
    activityType: string;  
    startDate: string;
    endDate: string;  
    statusName: string;
    statusId: number;
    menteeName: string;
    availableTimeId: number;
    hasMentorFeedback: boolean;
}

interface FeedbackFormValues {
    activityRating: number;
    activityFeedback: string;
    counterpartRating: number;
    maintainContact: boolean;
    isMentor: boolean;
}

const UpcomingActivitiesMentor: React.FC<UpcomingActivitiesMentorProps> = ({ onRefetch }) => {
    const dispatch = useDispatch();
    const user_personal_data = useSelector(selectPersonalData);
    const user_id = user_personal_data.Id;
    const [reload, setReload] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
    const [selectedAvailableTimeId, setSelectedAvailableTimeId] = useState<number | null>(null);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [counterpartName, setCounterpartName] = useState("");
    const { data: activities = [], isLoading: isLoadingActivities, refetch, error: activitiesError } = useGetUpcomingActivitiesByMentorQuery(user_id);
    const [sendActivityFeedback] = useSendActivityFeedbackMutation();

    useEffect(() => {
        if (reload) {
            refetch();
            setReload(false);
        }
    }, [reload, refetch]);

    useEffect(() => {
        refetch();  
    }, []);

    useEffect(() => {
        if (onRefetch) {
            onRefetch(refetch);
        }
    }, [onRefetch, refetch]);

    const handleEvaluation = (id: number, availableTimeId: number, menteeName: string) => {
        setSelectedActivityId(id);
        setSelectedAvailableTimeId(availableTimeId);
        setCounterpartName(menteeName);
        setOpenFeedbackDialog(true);
    };

    const handleFeedbackSubmit = async (values: FeedbackFormValues) => {
        if (selectedAvailableTimeId !== null) {
            const feedbackData = {
                ...values,
                availableTimeId: selectedAvailableTimeId,
                isMentor: true  // Indicar que el evaluador es un mentor
            };

            let new_alert = { id: `sending_feedback_${Date.now()}`, type: "loading", title: "Enviando feedback", desc: "", close: false, timeout: 0 };
            dispatch(addAlert({ alert: new_alert }));

            try {
                await sendActivityFeedback(feedbackData).unwrap();
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_sent_${Date.now()}`, type: "success", title: "Feedback enviado", desc: "El feedback ha sido enviado exitosamente", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
                setReload(true);
            } catch (error) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_error_${Date.now()}`, type: "error", title: "Error al enviar feedback", desc: "Hubo un error al enviar el feedback", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
            }
        }
        setOpenFeedbackDialog(false);
    };

    const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
        return error && 'status' in error;
    };

    const renderCellWithTooltip = (params: GridRenderCellParams<any>) => (
        <Tooltip title={params.value}>
            <span>{params.value}</span>
        </Tooltip>
    );

    const columnas: GridColDef[] = [
        { 
            headerName: "Evento", 
            field: "eventName", 
            flex: 1,
            minWidth: 150,
            renderCell: renderCellWithTooltip 
        },
        { 
            headerName: "Actividad", 
            field: "activityName", 
            flex: 1,
            minWidth: 200,
            renderCell: renderCellWithTooltip 
        },
        { 
            headerName: "Tipo", 
            field: "activityType", 
            flex: 1,
            minWidth: 150,
            renderCell: renderCellWithTooltip 
        },
        {
            headerName: "Inicio", 
            field: "startDate", 
            flex: 1,
            minWidth: 130,
            renderCell: (cellvalues) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Término", 
            field: "endDate", 
            flex: 1,
            minWidth: 130,
            renderCell: (cellvalues) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        { 
            headerName: "Estado", 
            field: "statusName", 
            flex: 1,
            minWidth: 170,
            renderCell: renderCellWithTooltip 
        },
        {
            headerName: "Opciones", 
            field: "options", 
            flex: 1,
            minWidth: 150,
            maxWidth: 200,
            renderCell: (cellvalues) => {
                const { statusId, hasMentorFeedback } = cellvalues.row;
                const isDisabled = statusId === 3 || statusId === 5 || (statusId === 4 && hasMentorFeedback);
                return (
                    <div className="d-flex flex-column justify-content-center align-items-stretch w-100">
                        <button
                            className="btn btn-primary btn-block p-1"
                            style={{
                                backgroundColor: isDisabled ? '#ccc' : '#007bff',
                                color: '#fff',
                                marginBottom: '5px',
                                textTransform: 'none',
                                padding: '5px 10px',
                                fontSize: '0.875rem',
                                minHeight: '30px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: isDisabled ? 'not-allowed' : 'pointer'
                            }}
                            onClick={() => !isDisabled && handleEvaluation(cellvalues.row.id, cellvalues.row.availableTimeId, cellvalues.row.menteeName)}
                            disabled={isDisabled}
                        >
                            Evaluar
                        </button>
                    </div>
                );
            }
        }
    ];

    const tableData = activities?.map((activity: Activity) => ({
        id: activity.id,
        eventName: activity.eventName,
        activityName: activity.activityName,
        activityType: activity.activityType,  
        startDate: activity.startDate,
        endDate: activity.endDate,  
        statusName: activity.statusName,
        statusId: activity.statusId,
        menteeName: activity.menteeName,
        availableTimeId: activity.availableTimeId,
        hasMentorFeedback: activity.hasMentorFeedback
    })) || [];

    return isLoadingActivities ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : activitiesError && isFetchBaseQueryError(activitiesError) && activitiesError.status === 404 ? (
        <div className='table-container'>
            <p>No hay actividades próximas disponibles.</p>
        </div>
    ) : (
        <div className='table-container' style={{ width: '100%', overflowX: 'auto' }}>
            <Tabla columnas={columnas} datos={tableData} autoHeight />
            <FeedbackDialog open={openFeedbackDialog} onClose={() => setOpenFeedbackDialog(false)} onSubmit={handleFeedbackSubmit} counterpartName={counterpartName} isMentor={true} />
        </div>
    );
};

export default UpcomingActivitiesMentor;
