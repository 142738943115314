
import { useEffect, useState } from 'react';

/* variables tabla */
import Tabla from '../../components/Tabla/Tabla';
import { GridColDef } from '@mui/x-data-grid';
//import { usuario_html, startup_html } from '../../utils/meetings';
import { usuario_html } from '../../utils/meetings';
//import { UsuarioReunion, Startup, Reunion, TiempoDonado, UsuarioSolicitud, Expertiz, Solicitud } from "../../interfaces/meetings"
import { personalData } from '../../interfaces/dimentions';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useGetUserMeetingsHistorialMutation, useGetUserMeetingRequestsHistorialMutation, useGetUserRequestedMeetingsRequestsHistorialMutation, usePostUserFeedbackMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { selectUserMeetingsHistorial, selectUserSolicitudesEnviadas, selectUserSolicitudesRecibidas, setUserMeetingsHistorial, setUserSolicitudesEnviadas, setUserSolicitudesRecibidas } from '../../redux/features/meetings/meetingsSlice';
import { useDispatch } from 'react-redux';
import "./Meetings.scss";
import GetActualTimeZone from '../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import moment from "moment"
import { changeDateTimeZone, changeDonatedTimeZone } from '../../utils/utilsForms';
import { Button, Dialog, DialogContent, DialogTitle , DialogActions } from '@mui/material';
import { Form, Formik } from 'formik';
import Label from '../../components/Form/Label';
import TextAreaInput from '../../components/Form/TextAreaInput';
import Select from '../../components/Form/Select';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
 
const MeetingsHistorialReuniones = () => {
    

    /* Código redirección término reunión*/
    const location = useLocation();
    const navigate = useNavigate();
    const { meetingId } = location.state || {};

    const [selectedMeetingRow, setSelectedMeetingRow] = useState<any>(null); // Inicializar como null o con un objeto válido según corresponda
    const [closeFirstFeedbackModal, setCloseFirstFeedbackModal] = useState(false);
    const [meetingFound, setMeetingFound] = useState(false);
    const [isHandlingFeedback, setIsHandlingFeedback] = useState(false);
    const [openModalNoMeetingFound, setOpenModalNoMeetingFound] = useState(false);
    const [firstOpenModalNoMeetingFound, setFirstOpenModalNoMeetingFound] = useState(false);
    const handleCloseNoMeetingFound = () => {
        setOpenModalNoMeetingFound(false);
        navigate('/meetings/next_meetings')
    };

    



    // useEffect(() => {
    //     if (selectedMeetingRow) {
    //         handleOpenDarFeedback(selectedMeetingRow);
    //     }
    // }, [selectedMeetingRow]);
    
 
      
    // useEffect(() => {
    //     if ( meetingId ) {
    //         handleOpenDarFeedback(row);
    //     }
    // }, []);
   


    // ifn código edirreción 
    const actual_time_zone = GetActualTimeZone()
    const textMeetings = useSelector(selectCurrentDictonary).meetings

    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado
    let user_id = user_personal_data.Id

    const user_solicitudes_enviadas: any = useSelector(selectUserSolicitudesEnviadas) //acceder a datos del usuario logueado
    const user_solicitudes_recibidas: any = useSelector(selectUserSolicitudesRecibidas) //acceder a datos del usuario logueado
    const user_meetings_historial: any = useSelector(selectUserMeetingsHistorial) //acceder a datos del usuario logueado

    // useEffect(() => {
    //     if (meetingId) {
    //         // Busca la reunión con el ID recibido
    //         const meetingData = user_meetings_historial.find(m => m.id === meetingId);
    //         if (meetingData && !meetingData.IsEvaluated) {
    //             handleOpenDarFeedback(meetingData);
    //         }
    //     }
    // }, [meetingId, user_meetings_historial]);
    
    useEffect(() => {
    //   console.log("meetingfounded", selectedMeetingRow)
      if(selectedMeetingRow ){
        // console.log("go")
        setCloseFirstFeedbackModal(true)
        handleOpenDarFeedback(selectedMeetingRow)
         
      }
    }, [selectedMeetingRow]);
    
  

    const [getMeetingsHistorial, { isLoadingGetMeetingsHistorial }]: any = useGetUserMeetingsHistorialMutation()
    const [getSoliciudesRecibidas, { isLoadingGetSolicitudesRecibidas }]: any = useGetUserMeetingRequestsHistorialMutation()
    const [getSoliciudesEnviadas, { isLoadingGetSolicitudesEnviadas }]: any = useGetUserRequestedMeetingsRequestsHistorialMutation()
    const [postUserFeedback, { isLoadingPostUserFeedback }]: any = usePostUserFeedbackMutation()


    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading_enviadas, setIsLoading_enviadas] = useState(false)
    const [isLoading_recibidas, setIsLoading_recibidas] = useState(false)
    const [isLoading_meetings, setIsLoading_meetings] = useState(false)
    const [initialCharge, setInitialCharge] = useState(false)

    useEffect(() => {
        if (!initialCharge) {
            handleReload()
            setInitialCharge(true)
        }
    }, []);

    async function handleReload() {

        setIsLoading_enviadas(true)
        setIsLoading_recibidas(true)
        setIsLoading_meetings(true)

        setIsLoading(true)
        async function handleReload_enviadas() {
            const res_enviadas: any = await getSoliciudesEnviadas({ user_id: user_id });
            const resData_enviadas = JSON.parse(res_enviadas.data.responseData)
            dispatch(setUserSolicitudesEnviadas({ user_solicitudes_enviadas: resData_enviadas }))
            setIsLoading_enviadas(false)
        }
        handleReload_enviadas()

        async function handleReload_recibidas() {
            const res_recibidas: any = await getSoliciudesRecibidas({ user_id: user_id });
            const resData_recibidas = JSON.parse(res_recibidas.data.responseData)
            dispatch(setUserSolicitudesRecibidas({ user_solicitudes_recibidas: resData_recibidas }))
            setIsLoading_recibidas(false)
        }
        handleReload_recibidas()


        async function handleReload_meetings_historial() {
            const res_meetings: any = await getMeetingsHistorial({ user_id: user_id });
            const resData_meetings = JSON.parse(res_meetings.data.responseData)
            dispatch(setUserMeetingsHistorial({ user_meetings_historial: resData_meetings }))
            setIsLoading_meetings(false)
        }
        handleReload_meetings_historial()
    }


    let datos_enviadas: any = []
    if (user_solicitudes_enviadas) {
        for (let i = 0; i < user_solicitudes_enviadas.length; i++) {
            const element = user_solicitudes_enviadas[i];
            datos_enviadas.push({

                ConfirmDateTime: element.ConfirmDateTime,
                CreatedAd: element.CreatedAd,
                id: element.Id,
                MeetingTypeId: element.MeetingTypeId,
                MeetingTypeName: element.MeetingTypeName,
                RequestStatusId: element.RequestStatusId,

                UserRequestId: element.UserRequestId,
                UserRequestLastName: element.UserRequestLastName,
                UserRequestName: element.UserRequestName,
                UserRequestProfilePicture: element.UserRequestProfilePicture,
                UserRequestRoles: element.UserRequestRoles,

                UserRequestedId: element.UserRequestedId,
                UserRequestedLastName: element.UserRequestedLastName,
                UserRequestedName: element.UserRequestedName,
                UserRequestedProfilePicture: element.UserRequestedProfilePicture,
                UserRequestedRoles: element.UserRequestedRoles,

                UserRequestedTimeId: element.UserRequestedTimeId, // FALTAN DATOS DE LA HORA DONADA

                RequestStatusReason: element.RequestStatusReason,
                UserRequestedEndTime: element.UserRequestedEndTime,
                UserRequestedStartTime: element.UserRequestedStartTime,
                UserRequestedTimeZoneOffset: element.UserRequestedTimeZoneOffset,


            })
        }
    }


    let datos_recibidas: any = []
    if (user_solicitudes_recibidas) {
        for (let i = 0; i < user_solicitudes_recibidas.length; i++) {
            const element = user_solicitudes_recibidas[i];
            datos_recibidas.push({
                ConfirmDateTime: element.ConfirmDateTime,
                CreatedAd: element.CreatedAd,
                id: element.Id,
                MeetingTypeId: element.MeetingTypeId,
                MeetingTypeName: element.MeetingTypeName,
                RequestStatusId: element.RequestStatusId,

                UserRequestId: element.UserRequestId,
                UserRequestLastName: element.UserRequestLastName,
                UserRequestName: element.UserRequestName,
                UserRequestProfilePicture: element.UserRequestProfilePicture,
                UserRequestRoles: element.UserRequestRoles,

                UserRequestedId: element.UserRequestedId,
                UserRequestedLastName: element.UserRequestedLastName,
                UserRequestedName: element.UserRequestedName,
                UserRequestedProfilePicture: element.UserRequestedProfilePicture,
                UserRequestedRoles: element.UserRequestedRoles,

                UserRequestedTimeId: element.UserRequestedTimeId, // FALTAN DATOS DE LA HORA DONADA

                RequestStatusReason: element.RequestStatusReason,
                UserRequestedEndTime: element.UserRequestedEndTime,
                UserRequestedStartTime: element.UserRequestedStartTime,
                UserRequestedTimeZoneOffset: element.UserRequestedTimeZoneOffset,
            })
        }
    }

    let datos_meetings: any = []
    if (user_meetings_historial) {
        for (let i = 0; i < user_meetings_historial.length; i++) {
            const element = user_meetings_historial[i];
            const id = element.MeetingId;
            const MeetingRequestId = element.MeetingRequestId;

            // busca id de solicitud para obtener datos de usuario y hora 
            let sol_enviada = datos_enviadas.filter((obj: any) => MeetingRequestId == obj.id);
            let sol_recibida = datos_recibidas.filter((obj: any) => MeetingRequestId == obj.id);
            let datos_solicitud = {}
            if (sol_enviada.length > 0) { // es enviada (tipo: 1)
                datos_solicitud = { ...sol_enviada[0], tipo: 1 }
            } else if (sol_recibida.length > 0) { // es recibida (tipo: 2)
                datos_solicitud = { ...sol_recibida[0], tipo: 2 }
            }
            let meetingInfo = {
                id: id,
                CreatedAt: element.CreatedAt,
                MeetingRequestId: element.MeetingRequestId,
                MeetingUrl: element.MeetingUrl,
                UserRequestAttendance: element.UserRequestAttendance,
                UserRequestedAttendance: element.UserRequestedAttendance,
                datos_solicitud: datos_solicitud,
                IsEvaluated: element.IsEvaluated
            };
            
       
            datos_meetings.push(meetingInfo);
            
        }
        
    }
    
    useEffect(() => {
        if (datos_meetings.length > 0   && meetingId  && !closeFirstFeedbackModal) {
           
            // console.log("DATOS FINISHED")
            const meeting = datos_meetings.find((meeting: any) => meeting.id === meetingId);
            if (meeting) {
                // console.log("Objeto encontrado:", meeting);
                if(meeting.datos_solicitud && !meetingFound && Object.keys(meeting.datos_solicitud).length > 0){
                // console.log("FOUND!!")
                // console.log("Longitud de datos_solicitud:", Object.keys(meeting.datos_solicitud).length);
                setMeetingFound(true)
                // setSelectedMeetingRow(meeting)
                // console.log(meeting.datos_solicitud) 
                // console.log(meeting.datos_solicitud.length)
                setSelectedMeetingRow(meeting)
                setCloseFirstFeedbackModal(true)
            }
                // if(!meetingFound){
                // setMeetingFound(true)
                // setSelectedMeetingRow(meeting)}
            } else {
                //console.log("No se encontró ningún objeto con el id:", meetingId);
                if(!firstOpenModalNoMeetingFound){
                setOpenModalNoMeetingFound(true);
                setFirstOpenModalNoMeetingFound(true); }
            }
        }
    }, [datos_meetings, meetingId]);





    let columnas: GridColDef[] = [
        {
            headerName: textMeetings.th_confirmacion, description: textMeetings.th_confirmacion, field: "CreatedAd", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let CreatedAt = row.CreatedAt
                let dict_createdAd = changeDateTimeZone(actual_time_zone, CreatedAt)

                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                {moment(dict_createdAd.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                            </div>
                            <div>
                                {dict_createdAd.time}
                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_2, description: textMeetings.table_1.header_2, field: "HoraReunion", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let UserRequestedStartTime = row.datos_solicitud.UserRequestedStartTime
                let UserRequestedEndTime = row.datos_solicitud.UserRequestedEndTime
                let fecha_inicio = UserRequestedStartTime.split("T")[0]
                let hora_inicio = UserRequestedStartTime.split("T")[1].split(":")[0] + ":" + UserRequestedStartTime.split("T")[1].split(":")[1]
                let hora_termino = UserRequestedEndTime.split("T")[1].split(":")[0] + ":" + UserRequestedEndTime.split("T")[1].split(":")[1]

                let dict = changeDonatedTimeZone(actual_time_zone, fecha_inicio, hora_inicio, hora_termino)

                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                {moment(dict.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                            </div>
                            <div>
                                {dict.startTime + " - " + dict.endTime}
                            </div>
                        </div>
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_3, description: textMeetings.table_1.header_3, field: "UserRequestedId",
            flex: 1, minWidth: 200,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let user_id = row.datos_solicitud.UserRequestedId
                let nombre = row.datos_solicitud.UserRequestedName
                let apellido = row.datos_solicitud.UserRequestedLastName
                let url_foto = row.datos_solicitud.UserRequestedProfilePicture
                let roles = row.datos_solicitud.UserRequestedRoles
                return (
                    <div className='d-flex flex justify-content-center align-items-center w-100'>
                        {usuario_html(user_id, nombre, apellido, url_foto, roles)}
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.th_reunion_por, description: textMeetings.th_reunion_por, field: "UserRequestId",
            flex: 1, minWidth: 200,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let user_id = row.datos_solicitud.UserRequestId
                let nombre = row.datos_solicitud.UserRequestName
                let apellido = row.datos_solicitud.UserRequestLastName
                let url_foto = row.datos_solicitud.UserRequestProfilePicture
                let roles = row.datos_solicitud.UserRequestRoles
                return (
                    <div className='d-flex flex justify-content-center align-items-center w-100'>
                        {usuario_html(user_id, nombre, apellido, url_foto, roles)}
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.th_tipo_reunion, description: textMeetings.th_tipo_reunion, field: "MeetingTypeName", flex: 2, minWidth: 230,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                // let MeetingTypeName = row.datos_solicitud.MeetingTypeName
                return (
                    <div className='contenedor-tipo-solicitud'>
                        {/* <p className='tipo-solicitud'>{MeetingTypeName}</p> */}
                        <p className='tipo-solicitud'>{textMeetings.type_meetings[row.datos_solicitud.MeetingTypeId]}</p>

                    </div>
                )
            }
        },    
       
        {
            headerName: textMeetings.th_evaluate, description: textMeetings.table_1.header_6, field: "Opciones_html", sortable: false, flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let id = row.id
                let IsEvaluated = row.IsEvaluated
                // console.log("ID FROM BUTTON", id)
                // console.log("ROWS FROM BUTTON", row)
                
                // console.log("IS EVALUTAED FROM BUTTON", row.IsEvaluated)
                //  console.log("ROWS FROM BUTTON", row.MeetingRequestId)
                // console.log("meetingid",meetingId)
                // console.log(row.id)

            
                return (
                    <>
                        {opciones_html(id, row, IsEvaluated)}
                    </>
                )
            }
        },
    ]

    let table_html = <></>
    if (isLoading_enviadas || isLoading_recibidas || isLoading_meetings) {
        table_html = <div className='table-container-loading'>
            <p>{textMeetings.laoding_table}</p>
        </div>
    } else {
        table_html = <div className='table-container'>
            <Tabla columnas={columnas} datos={datos_meetings}></Tabla>
        </div>
    }

    function opciones_html(MeetingId: number, row: any, IsEvaluated: any) {
        if (IsEvaluated) {
            return (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                </div>
            )
        } else {
            return (
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <button className="btn btn-primary btn-block p-1" onClick={() => handleOpenDarFeedback(row)}>{textMeetings.button_evaluate}</button>
                </div>
            )
        }
    }


    function handleOpenDarFeedback(row: any) {
         
            setIsHandlingFeedback(true)
      
        let id_meeting = row.id
        let datos_solicitud = row.datos_solicitud
        let UserRequestId = datos_solicitud.UserRequestId
        let UserRequestedId = datos_solicitud.UserRequestedId
        let usuario_feedback = {}
        if (UserRequestId == user_id) {
            usuario_feedback = {
                id: UserRequestedId,
                name: datos_solicitud.UserRequestedName,
                id_meeting: id_meeting
            }
        } else {
            usuario_feedback = {
                id: UserRequestId,
                name: datos_solicitud.UserRequestName,
                id_meeting: id_meeting
            }
        }
        setActualValuesCreateFeedback(empty_values)
        set_usuario_dar_feedback(usuario_feedback)
        handleClickDialogDarFeedback()
        setIsHandlingFeedback(false); 
    }
    const [open_dialog_dar_feedback, set_open_dialog_dar_feedback] = useState(false)
    const [usuario_dar_feedback, set_usuario_dar_feedback] = useState<any>({})
    function handleClickDialogDarFeedback() {
        set_open_dialog_dar_feedback(!open_dialog_dar_feedback)
    }

    const empty_values = {
        "feedback_app_stars": 0,
        "feedback_app_desc": "",
        "feedback_user_stars": 0,
        "feedback_user_desc": "",
    }
    const [actual_values_create_feedback, setActualValuesCreateFeedback] = useState<any>({ ...empty_values });

    const validateCreateFeedback = (values: any) => {
        const errors: any = {}

        if (!values.feedback_app_stars) {
            errors.feedback_app_stars = textMeetings.label_10
        }
        if (!values.feedback_user_stars) {
            errors.feedback_user_stars = textMeetings.label_10
        }
        if (values.feedback_app_desc && values.feedback_app_desc.length > 400) {
            errors.feedback_app_desc = "max 400 caracteres"
        }
        if (values.feedback_user_desc && values.feedback_user_desc.length > 400) {
            errors.feedback_user_desc = "max 400 caracteres"
        }
        setActualValuesCreateFeedback(values)
        return errors
    }


    async function handleSubmitCreateFeedback(values: any, { setStatus, setErrors }: any) {

        handleClickDialogDarFeedback()

        let new_alert = { id: "enviando_feedback", type: "loading", title: "Enviando feedback...", desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))

        try {
            let item = {
                "meetingId": usuario_dar_feedback.id_meeting,
                "userId": user_id,
                "appEvaluationStars": actual_values_create_feedback.feedback_app_stars,
                "appEvaluationDesc": actual_values_create_feedback.feedback_app_desc ? actual_values_create_feedback.feedback_app_desc : "",
                "userEvaluatedId": usuario_dar_feedback.id,
                "userEvaluationStars": actual_values_create_feedback.feedback_user_stars,
                "userEvaluationDesc": actual_values_create_feedback.feedback_user_desc ? actual_values_create_feedback.feedback_user_desc : ""
            }
            await postUserFeedback({ body: item });
            dispatch(deleteAlert({ alert_id: "enviando_feedback" }));
            let new_alert2 = { type: "success", timeout: 2000, title: "Feedback enviado correctamente", desc: "", close: true }
            dispatch(addAlert({ alert: new_alert2 }))
            handleReload()
        } catch (error) {
            dispatch(deleteAlert({ alert_id: "enviando_feedback" }));
            let new_alert2 = { type: "error", timeout: 2000, title: "El feedback no se ha enviado", desc: "", close: true }
            dispatch(addAlert({ alert: new_alert2 }))
        }
    }
    if(meetingId){

        // const row = {
        //     id: 426,
        //     CreatedAt: '2024-05-10T16:59:39Z',
        //     MeetingRequestId: 693,
        //     MeetingUrl: undefined,
        //     UserRequestAttendance: 1,
        //     // Otros valores que desees incluir
        //   };
       
    }


    return (
        <>
            <div className='row'>
                <div className='col-12 mb-4'>
                    <div className='d-flex justify-content-start align-items-center mb-2'>
                        <h3 className='mb-0 mr-2 font-italic'><b>{textMeetings.titulo_5}</b></h3>
                        <div className="btn-refresh mt-1" onClick={handleReload}>
                            <AutorenewIcon fontSize='small' />
                        </div>
                    </div>
                    <p>
                        {textMeetings.desc_5}
                    </p>
                </div>
                <div className='col-12 mb-4'>

                    <div className='table-container'>
                        {table_html}
                    </div>
                </div>
            </div>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={open_dialog_dar_feedback}
                onClose={handleClickDialogDarFeedback}
            >
                <DialogTitle >
                    Feedback
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={actual_values_create_feedback}
                        validate={validateCreateFeedback}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={handleSubmitCreateFeedback}
                    >
                        <Form>
                            <div className='row'>
                                <div className='col-12 mb-4'>
                                    <Select className="w-100 " name="feedback_app_stars" label={textMeetings.label_5} required={true}>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </Select>
                                </div>
                                <div className='col-12 mb-4'>
                                    <Label className={"label label-primary m-0 p-0"} label={textMeetings.label_6} required={false}></Label>
                                    <p className='m-0  p-0'>{textMeetings.info_1}</p>
                                    <TextAreaInput className="w-100" name="feedback_app_desc" label="" required={true} />
                                </div>
                                <div className='col-12 mb-4'>
                                    <Select className="w-100 " name="feedback_user_stars" label={textMeetings.label_7 + (usuario_dar_feedback.name)} required={true}>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </Select>
                                </div>
                                <div className='col-12 mb-4'>
                                    <Label className={"label label-primary m-0 p-0"} label={textMeetings.label_8 + usuario_dar_feedback.name} required={false}></Label>
                                    <p className='m-0  p-0'>{textMeetings.info_1}</p>
                                    <TextAreaInput className="w-100" name="feedback_user_desc" label="" required={true} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button onClick={handleClickDialogDarFeedback} className="btn btn-outline-danger mr-2">{textMeetings.btn_cancel}</Button>
                                <Button type="submit" className="btn btn-primary">{textMeetings.btn_guardar}</Button>
                            </div>
                        </Form>
                    </Formik>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openModalNoMeetingFound}
                onClose={handleCloseNoMeetingFound}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="xs"
                sx={{ width: '600px', maxHeight: '360px', margin: 'auto' }} 

            >
                <DialogTitle id="alert-dialog-title">{"Por favor, espere..."}</DialogTitle>
                <DialogContent sx={{ overflow: 'hidden' }}>
                    <DialogContent>
                        Debe esperar a que la reunión comience para poder realizar la evaluación. Recuerde que el ingreso a la misma se habilita 10 minutos antes de la hora de inicio pactada.
                    </DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button style={{ padding: '10px 20px', fontSize: '0.875rem' }} onClick={handleCloseNoMeetingFound} className="btn btn-primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default MeetingsHistorialReuniones;