import { apiSlice } from "../../api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        postUserEliminarFotoPerfilPublico: builder.mutation({ //REPETIDO
            query: values => ({
                url: '/api/Users/EliminarFotoPerfilPublico/' + values.user_id,
                method: 'POST',
            })
        }),
        /* User Public Data */
        getUserPublicData: builder.mutation({ //REPETIDO
            query: values => ({
                url: '/api/Users/PublicData/' + values.user_id,
                method: 'GET',
            })
        }),
        putUserPublicData: builder.mutation({
            query: (values: any) => ({
                url: '/api/Users/PublicData/' + values.user_id,
                method: 'PUT',
                body: values.body
            })
        }),
        getPublicData: builder.mutation({ //usado para el perfil publico... no tiene authentication, no es necesario
            query: (values: any) => ({
                url: '/api/Users/PublicData/' + (values.user_id),
                method: 'GET',
            })
        }),
        /* User personal data */
        getUserPersonalData: builder.mutation({
            query: values => ({
                url: '/api/Users/PersonalData/' + values.user_id,
                method: 'GET',
            })
        }),
        putPersonalData: builder.mutation({
            query: values => ({
                url: '/api/Users/PersonalData/' + values.id,
                method: 'PUT',
                body: { ...values.body }
            })
        }),
        /* User tags */
        getUserTags: builder.mutation({
            query: values => ({
                url: '/api/Users/UserTags/' + values.user_id,
                method: 'GET',
            })
        }),
        putUserTags: builder.mutation({
            query: values => ({
                url: '/api/Users/UserTags/' + values.user_id,
                method: 'PUT',
                body: [...values.body]
            })
        }),
        /* User expertises */
        getUserExpertises: builder.mutation({
            query: values => ({
                url: '/api/Expertises/GetUserExpertises?userId=' + values.user_id,
                method: 'GET',
            })
        }),
        putUserExpertises: builder.mutation({
            query: values => ({
                url: '/api/Expertises/UpdateUserExpertise',
                method: 'PUT',
                body: { ...values }
            })
        }),
        postUserExpertises: builder.mutation({
            query: values => ({
                url: '/api/Expertises/AddUserExpertise',
                method: 'POST',
                body: { ...values }
            })
        }),
        deleteUserExpertises: builder.mutation({
            query: values => ({
                url: '/api/Expertises/RemoveUserExpertise/' + values.expertiseId,
                method: 'DELETE',
                body: { ...values }
            })
        }),
        /* User Languages */
        getUserLanguages: builder.mutation({
            query: values => ({
                url: '/api/Users/UserLanguages/' + values.user_id,
                method: 'GET',
            })
        }),
        putUserLanguages: builder.mutation({
            query: values => ({
                url: '/api/Users/UserLanguages/' + values.user_id,
                method: 'PUT',
                body: [...values.body]
            })
        }),
        /* User Public Roles */
        getUserRoles: builder.mutation({
            query: (values: any) => ({
                url: '/api/Users/UserRoles/' + (values.user_id),
                method: 'GET',
            })
        }),
        putUserRoles: builder.mutation({
            query: (values: any) => ({
                url: '/api/Users/UserRoles/',
                method: 'PUT',
                body: { ...values.body }
            })
        }),
        /* User Interests */
        getUserInterests: builder.mutation({
            query: (values: any) => ({
                url: '/api/Users/UserInterests/' + (values.user_id),
                method: 'GET',
            })
        }),
        postUserInterests: builder.mutation({
            query: (values: any) => ({
                url: '/api/Interests/AddUserInterests',
                method: 'POST',
                body: { ...values.body }
            })
        }),

        
        getUserNotifications: builder.mutation({
            query: (values: any) => ({
                url: '/api/Notification/GetUserNotifications/' + (values.user_id)+"?readed=false",
                method: 'GET',
            })
        }),

        putUpdatedNotificationRead: builder.mutation({
            query: (values: any) => ({
                url: '/api/Notification/UpdateNotificationRead/',
                method: 'PUT',
                body: {...values.body}
            })
        }),

        getUpcomingMeetings: builder.mutation({
            query: (values: any) => ({
                url: '/api/Notification/GetUpcomingMeetings/' + (values.user_id),
                method: 'GET',
            }),
        }),
        postGetUsersInfo: builder.mutation({
            query: (directDonationIds: any) => ({
                url: '/api/Users/GetUsersInfo',
                method: 'POST',
                body: directDonationIds,
            })
        }),
        putDeleteNotification: builder.mutation({
            query: (values: any) => ({
                url: '/api/Notification/DeleteNotification/' + (values.notificationId),
                method: 'PUT',
                body: {...values.body}
            })
        }),

        postAddUserContact: builder.mutation({
            query: (addUserContactData) => ({
                url: '/api/Users/AddUserContact/' + addUserContactData.userId,
                method: 'POST',
                body: addUserContactData,
            }),
        }),

        getUserContacts: builder.mutation({
            query: (userId) => ({
                url: `/api/Users/GetUserContacts/${userId}`,
                method: 'GET'  
            })
        }),

        postDeleteUserContact: builder.mutation({
            query: (deleteUserContactData) => ({
                url: '/api/Users/DeleteContact',   
                method: 'POST',
                body: deleteUserContactData,       
            })
        }),
        getCheckMutualContact: builder.mutation({
            query: (values: any) => ({
                url: `/api/Users/CheckContact?UserId=${values.UserId}&ContactUserId=${values.ContactUserId}`,
                method: 'GET'
            }),
        }),
        getUserByEmail: builder.query({
            query: (email) => ({
                url: `/api/Users/GetUserByEmail/${email}`,
                method: 'GET',
            })
        })
    
        
        
      


    })
})


export const { /* siempre poner use al inicio del metodo */
    useGetUserPersonalDataMutation, // personal data
    usePutPersonalDataMutation,
    usePutUserPublicDataMutation, // public data
    useGetUserPublicDataMutation,
    usePutUserTagsMutation, // tags
    useGetUserTagsMutation,
    useGetUserExpertisesMutation, // expertises
    usePostUserExpertisesMutation,
    useDeleteUserExpertisesMutation,
    usePutUserExpertisesMutation,
    usePutUserLanguagesMutation, // languages
    useGetUserLanguagesMutation,
    useGetUserRolesMutation, // roles
    usePutUserRolesMutation,
    useGetUserInterestsMutation, // interests
    usePostUserInterestsMutation,
    useGetUserNotificationsMutation,//notificaciones
    usePutUpdatedNotificationReadMutation,
    useGetUpcomingMeetingsMutation,
    usePostUserEliminarFotoPerfilPublicoMutation,
    usePostGetUsersInfoMutation,
    usePutDeleteNotificationMutation,
    usePostAddUserContactMutation,
    useGetUserContactsMutation,
    usePostDeleteUserContactMutation,
    useGetCheckMutualContactMutation,
    useGetUserByEmailQuery,
    useLazyGetUserByEmailQuery,
} = userApiSlice


