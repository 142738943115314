import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';

const adminIds = [19044, 19131, 1469, 20003];

const ScheduleMeeting = () => {
  const [emailUser1, setEmailUser1] = useState(''); // Estado para el valor del correo electrónico del usuario 1
  const [emailUser2, setEmailUser2] = useState(''); // Estado para el valor del correo electrónico del usuario 2

  const navigate = useNavigate();
  const userPersonalData = useSelector(selectPersonalData);

  useEffect(() => {
    if (!adminIds.includes(userPersonalData.Id)) {
      navigate('/home');
    }
  }, [userPersonalData, navigate]);

  if (!adminIds.includes(userPersonalData.Id)) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Agendar reuniones
      </Typography>
      <TextField
        label="Correo Electrónico Usuario 1"
        variant="outlined"
        fullWidth
        value={emailUser1}
        onChange={(e) => setEmailUser1(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Correo Electrónico Usuario 2"
        variant="outlined"
        fullWidth
        value={emailUser2}
        onChange={(e) => setEmailUser2(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary">
        Seleccionar fecha y hora
      </Button>
    </Box>
  );
};

export default ScheduleMeeting;
