import { useEffect, useState } from 'react';
import TextInput from '../../components/Form/TextInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import { useAssignMentorByAdminMutation, useAssignMenteeByAdminMutation, useCancelMenteeByAdminMutation, useCancelMentorByAdminMutation } from '../../redux/features/events/eventsApiSlice';

const BlockScheduleForm = ({ handleClose, handleSubmit, isSubmitting, initialValues, onSuccess }: any) => {
    const dispatch = useDispatch();
    const [assignMentorByAdmin] = useAssignMentorByAdminMutation();
    const [assignMenteeByAdmin] = useAssignMenteeByAdminMutation();
    const [cancelMenteeByAdmin] = useCancelMenteeByAdminMutation();
    const [cancelMentorByAdmin] = useCancelMentorByAdminMutation();
    const [mentorEmail, setMentorEmail] = useState(initialValues.mentorEmail || '');
    const [menteeEmail, setMenteeEmail] = useState(initialValues.menteeEmail || '');
    const [mentorAssigned, setMentorAssigned] = useState(!!initialValues.mentorEmail);
    const [menteeAssigned, setMenteeAssigned] = useState(!!initialValues.menteeEmail);
    const [blockId, setBlockId] = useState(initialValues.id);
    const [openConfirmMentorDialog, setOpenConfirmMentorDialog] = useState(false);
    const [openConfirmMenteeDialog, setOpenConfirmMenteeDialog] = useState(false);
    const [openConfirmRemoveMenteeDialog, setOpenConfirmRemoveMenteeDialog] = useState(false);
    const [openConfirmRemoveMentorDialog, setOpenConfirmRemoveMentorDialog] = useState(false);
    const [payloadToConfirm, setPayloadToConfirm] = useState<any>(null);
    const [isMentor, setIsMentor] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        mentor: Yup.string().email("Correo inválido"),
        mentee: Yup.string().email("Correo inválido"),
    });

    const defaultValues = {
        mentorEmail: '',
        menteeEmail: '',
        id: null
    };

    const mergedInitialValues = { ...defaultValues, ...initialValues };

    const handleConfirmAssignMentor = () => {
        const payload = { email: mentorEmail.trim(), blockId: blockId };
        setPayloadToConfirm(payload);
        setIsMentor(true);
        setOpenConfirmMentorDialog(true);
    };

    const handleConfirmAssignMentee = () => {
        const payload = { email: menteeEmail.trim(), blockId: blockId };
        setPayloadToConfirm(payload);
        setIsMentor(false);
        setOpenConfirmMenteeDialog(true);
    };

    const handleConfirmedAssignMentor = async () => {
        let new_alert = { id: `assigning_mentor_${Date.now()}`, type: "loading", title: "Asignando mentor...", desc: "", close: false, timeout: 2000 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const result = await assignMentorByAdmin(payloadToConfirm).unwrap();
            //console.log('Mentor assigned:', result);
            setMentorAssigned(true);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = { id: `mentor_assigned_${Date.now()}`, type: "success", title: "Mentor asignado con éxito", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: success_alert }));
            onSuccess();   
        } catch (error) {
            //console.error('Error assigning mentor:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = { id: `assign_mentor_error_${Date.now()}`, type: "error", title: "Error al asignar mentor", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmMentorDialog(false);
        }
    };

    const handleConfirmedAssignMentee = async () => {
        let new_alert = { id: `assigning_mentee_${Date.now()}`, type: "loading", title: "Asignando mentee...", desc: "", close: false, timeout: 2000 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const result = await assignMenteeByAdmin(payloadToConfirm).unwrap();
            //console.log('Mentee assigned:', result);
            setMenteeAssigned(true);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = { id: `mentee_assigned_${Date.now()}`, type: "success", title: "Mentee asignado con éxito", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: success_alert }));
            onSuccess();
        } catch (error) {
            console.error('Error assigning mentee:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = { id: `assign_mentee_error_${Date.now()}`, type: "error", title: "Error al asignar mentee", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmMenteeDialog(false);
        }
    };

    const handleConfirmRemoveMentee = () => {
        const payload = { email: menteeEmail.trim(), blockId: blockId };
        setPayloadToConfirm(payload);
        setOpenConfirmRemoveMenteeDialog(true);
    };

    const handleConfirmedRemoveMentee = async () => {
        let new_alert = { id: `removing_mentee_${Date.now()}`, type: "loading", title: "Eliminando mentee...", desc: "", close: false, timeout: 2000 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const result = await cancelMenteeByAdmin(payloadToConfirm).unwrap();
            //console.log('Mentee removed:', result);
            setMenteeAssigned(false);
            setMenteeEmail('');
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = { id: `mentee_removed_${Date.now()}`, type: "success", title: "Mentee eliminado con éxito", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: success_alert }));
            onSuccess();
        } catch (error) {
            console.error('Error removing mentee:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = { id: `remove_mentee_error_${Date.now()}`, type: "error", title: "Error al eliminar mentee", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmRemoveMenteeDialog(false);
        }
    };

    const handleConfirmRemoveMentor = () => {
        const payload = { email: mentorEmail.trim(), blockId: blockId };
        setPayloadToConfirm(payload);
        setOpenConfirmRemoveMentorDialog(true);
    };

    const handleConfirmedRemoveMentor = async () => {
        let new_alert = { id: `removing_mentor_${Date.now()}`, type: "loading", title: "Eliminando mentor...", desc: "", close: false, timeout: 2000 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            const result = await cancelMentorByAdmin(payloadToConfirm).unwrap();
            //console.log('Mentor removed:', result);
            setMentorAssigned(false);
            setMentorEmail('');
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = { id: `mentor_removed_${Date.now()}`, type: "success", title: "Mentor eliminado con éxito", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: success_alert }));
            onSuccess();
            handleClose();   
        } catch (error) {
            console.error('Error removing mentor:', error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = { id: `remove_mentor_error_${Date.now()}`, type: "error", title: "Error al eliminar mentor", desc: "", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: error_alert }));
        } finally {
            setOpenConfirmRemoveMentorDialog(false);
        }
    };

    const handleRemoveMentor = () => {
        handleConfirmRemoveMentor();
    };

    const handleRemoveMentee = () => {
        handleConfirmRemoveMentee();
    };

    useEffect(() => {
        if (initialValues.id) {
            setBlockId(initialValues.id);
        }
        if (initialValues.mentorEmail) {
            setMentorEmail(initialValues.mentorEmail);
        } else {
            setMentorEmail('');
        }
        if (initialValues.menteeEmail) {
            setMenteeEmail(initialValues.menteeEmail);
        } else {
            setMenteeEmail('');
        }
    }, [initialValues.id, initialValues.mentorEmail, initialValues.menteeEmail]);

    return (
        <>
            <Formik
                initialValues={mergedInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} container spacing={2} alignItems="flex-end">
                                <Grid item xs={10}>
                                    <TextInput
                                        name="mentor"
                                        label="Mentor"
                                        style={{ width: '100%' }}
                                        placeholder="Sin asignar, ingrese correo..."
                                        value={mentorEmail}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMentorEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <IconButton
                                        sx={{ alignSelf: 'flex-end', marginTop: '30px', paddingLeft: '8px' }}
                                        onClick={mentorAssigned ? handleRemoveMentor : handleConfirmAssignMentor}
                                    >
                                        {mentorAssigned ? <PersonRemoveIcon style={{ color: 'black' }} /> : <PersonAddIcon style={{ color: 'black' }} />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={2} alignItems="flex-end">
                                <Grid item xs={10}>
                                    <TextInput
                                        name="mentee"
                                        label="Mentee"
                                        style={{ width: '100%' }}
                                        placeholder="Sin asignar, ingrese correo..."
                                        value={menteeEmail}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMenteeEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <IconButton
                                        sx={{ alignSelf: 'flex-end', marginTop: '30px', paddingLeft: '8px' }}
                                        onClick={menteeAssigned ? handleRemoveMentee : handleConfirmAssignMentee}
                                    >
                                        {menteeAssigned ? <PersonRemoveIcon style={{ color: 'black' }} /> : <PersonAddIcon style={{ color: 'black' }} />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className="d-flex justify-content-end mt-3">
                            <Button type="button" variant="contained" color="primary" onClick={handleClose} disabled={isSubmitting}>
                                Volver
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
            <Dialog open={openConfirmMentorDialog} onClose={() => setOpenConfirmMentorDialog(false)}>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas añadir al mentor?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedAssignMentor} color="primary">
                        Confirmar
                    </Button>
                    <Button onClick={() => setOpenConfirmMentorDialog(false)} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmMenteeDialog} onClose={() => setOpenConfirmMenteeDialog(false)}>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas añadir al mentee?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedAssignMentee} color="primary">
                        Confirmar
                    </Button>
                    <Button onClick={() => setOpenConfirmMenteeDialog(false)} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmRemoveMenteeDialog} onClose={() => setOpenConfirmRemoveMenteeDialog(false)}>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas eliminar al mentee?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedRemoveMentee} color="primary">
                        Confirmar
                    </Button>
                    <Button onClick={() => setOpenConfirmRemoveMenteeDialog(false)} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmRemoveMentorDialog} onClose={() => setOpenConfirmRemoveMentorDialog(false)}>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro de que deseas eliminar al mentor?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmedRemoveMentor} color="primary">
                        Confirmar
                    </Button>
                    <Button onClick={() => setOpenConfirmRemoveMentorDialog(false)} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default BlockScheduleForm;
