import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CardStartupHome.scss';
import Card from "../Card/Card";
import { capitalizeFirstLetter, getCharLimit } from '../../utils/utilsText';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { useSelector } from 'react-redux';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import GetUserDonatedTime from '../../hooks/meetings/GetUserDonatedTime/GetUserDonatedTime';
import { DialogTiempoReunion } from '../../pages/UserProfileExternal/DialogTiempoReunion';

import { Dialog, DialogContent, DialogTitle, Avatar, Tooltip, Button, Link, Rating, useMediaQuery , useTheme } from '@mui/material';
import { useGetUserDonatedTimeMutation } from '../../redux/features/meetings/meetingsApiSlice';


function CardStartupHome({ handleClickOpenDialogPublicProfile, StartupName, StartupPicture, StartupDescription, UserName, UserLastName, UserProfilePicture, UserId, PercentageSim
	
	 }: any) {
    
    const navigate = useNavigate();
    const [isSaved, setIsSaved] = useState(false);
    const dictionary = useSelector(selectCurrentDictonary);
	const textPublicProfile = useSelector(selectCurrentDictonary).public_profile

	// nuevo
    const [isLoadingButton, setIsLoadingButton] = useState(true)
    const [valueDonatedTime, setValueDonatedTime] = useState<any>(false)
    const [initialChargeLoadingButton, setInitialChargeLoadingButton] = useState(true)
	const [get]: any = useGetUserDonatedTimeMutation()

    const fetchDonatedTime = async () => {
        setIsLoadingButton(true)
        const res: any = await get({ user_id: UserId, type: 0});
        // console.log("sad")
        const resData = JSON.parse(res.data.responseData)
		setValueDonatedTime(resData)
        setIsLoadingButton(false)
        setInitialChargeLoadingButton(false)
    }

    useEffect(() => {
        if (initialChargeLoadingButton) {
            fetchDonatedTime()
        }
    }, [valueDonatedTime]); 
	// fin nuevo


    let user_image_url = UserProfilePicture ? UserProfilePicture : "/media/iconos/icono-persona.svg";
    let startup_image_url = StartupPicture && !StartupPicture.includes("static.iris") ? StartupPicture : "/media/iconos/icono-startup.svg";

    function handleOnClickFav() {
        setIsSaved(!isSaved);
    }

    let fav_icon = <StarBorderOutlinedIcon className="user-save-button" onClick={handleOnClickFav}></StarBorderOutlinedIcon>;
    if (isSaved) {
        fav_icon = <StarOutlinedIcon className="user-save-button user-saved" onClick={handleOnClickFav}></StarOutlinedIcon>;
    }

    function handleOnClickUserCard() {
        handleClickOpenDialogPublicProfile();
    }

    let nombre_corto_usuario = `${UserName.split(" ")[0]} ${UserLastName.split(" ")[0]}`;
	

    /* -------------- DIALOGOS SOLICITAR TIEMPO Y REUNION ---------------------------------------------------------------------- */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [sol_reu_open, set_sol_reu_open] = useState(false)
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open)
    }
	/* BOTON SOLICITUD DE REUNION O DONAR TIEMPO */
	let btn_solicitar_reunion_html = <></>
	

    if (!isLoadingButton) {
		if (valueDonatedTime && valueDonatedTime.length > 0) {
            btn_solicitar_reunion_html = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn btn-solicitar-reunion-card-home' onClick={handleClickDialog}>{textPublicProfile.btn_solicitar_reunion}</button> {/*textPublicProfile.btn_solicitar_reunion texto del diccionario, de referencia crear nueva key*/}
			</>
        } else {
            btn_solicitar_reunion_html  = <>
			<DialogTiempoReunion
                user_id_profile={UserId} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
            />
			<button className='btn btn-solicitar-reunion-card-home-2' onClick={handleClickDialog}>{textPublicProfile.btn_solicitar_tiempo}</button> {/*textPublicProfile.btn_solicitar_tiempo texto del diccionario, de referencia crear nueva key*/}
			</>
        }
	
	} else {
		btn_solicitar_reunion_html = <>Cargando...</>
	}
	


    return (
        <Card className='card-usuario'  >
            <div className='d-flex flex-column flex-xl-row justify-content-start align-items-center p-2 p-md-0'>
                <div className="avatar-usuario-container mr-2 mb-3 mb-xl-0">
                    <Avatar className="avatar-usuario" alt={StartupName} src={startup_image_url} ></Avatar>
                    <Tooltip arrow className="avatar-usuario-match-percentage" title="Porcentaje de match">
                        <p className=" ">{Math.floor(PercentageSim)}%</p>
                    </Tooltip>
                </div>
                <div className='row no-gutters w-100 '>
                    <div className='col-xl-4 col-12 h-card-user px-2'> {/* titulo, cargo y estrellas */}
                        <div className='d-flex flex-column justify-content-center align-items-center align-items-xl-start  h-card-user '>
                            <h6 className="card-usuario-title text-center text-xl-left font-bolder mb-2 mb-xl-0"><b>{getCharLimit(StartupName + "", 60)}</b></h6>
							
							{btn_solicitar_reunion_html} {/* boton solicitar reunion */}
                        
						
                        </div>
                    </div>
                    <div className='col-xl-5 col-12 h-card-user px-3 px-md-0 pr-md-3'> {/* desc */}
                        <div className='d-flex justify-content-center justify-content-xl-start align-items-center  h-card-user mb-3 mb-xl-0 w-100'>
                            <p className='card-usuario-desc text-center text-xl-left px-0 px-md-5 px-xl-2'>{getCharLimit(StartupDescription + "", 150)}</p>
                        </div>
                    </div>
                    <div className='col-xl-3 col-12 px-2'> {/* desc */}
                        <div className='d-flex flex-column justify-content-center align-items-center h-card-user '>
                            <Avatar className="startup-avatar-usuario mb-2" alt={UserName} src={user_image_url} >{UserName ? UserName[0] : ""}</Avatar>
                            <p className='name-company-usuario text-center'>{getCharLimit(capitalizeFirstLetter(nombre_corto_usuario) + "", 60)}</p>
                            <button onClick={handleOnClickUserCard} className='btn btn-primary btn-ver-perfil-card' >{textPublicProfile.ver_perfil}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default CardStartupHome;
