import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { useGetAllActivitiesQuery, useGetActivityAvailableTimesMenteeQuery, useAssignMenteeToBlockMutation } from '../../redux/features/events/eventsApiSlice';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import Tabla from '../../components/Tabla/Tabla';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import './ActivityTableMentee.scss'; // Importar estilos para mantener el mismo formato

interface ActivityTableMenteeProps {
    handleViewSchedules: (id: number) => void;
}

const ActivityTableMentee: React.FC<ActivityTableMenteeProps> = ({ handleViewSchedules }) => {
    const dispatch = useDispatch();
    const user_personal_data = useSelector(selectPersonalData);
    const user_id = user_personal_data.Id;
    const [open, setOpen] = useState(false);
    const [openBlocks, setOpenBlocks] = useState(false);
    const [activityId, setActivityId] = useState<number | null>(null);
    const [blockData, setBlockData] = useState<any[]>([]);
    const { data: activities, isLoading: isLoadingActivities, refetch: refetchActivities } = useGetAllActivitiesQuery({});

    const { data: schedules, isLoading: isLoadingSchedules, refetch: refetchSchedules, error: schedulesError } = useGetActivityAvailableTimesMenteeQuery(activityId ?? -1, {
        skip: activityId === null,
    });
    const [assignMenteeToBlock] = useAssignMenteeToBlockMutation();

    useEffect(() => {
        refetchActivities();
    }, [refetchActivities]);

    useEffect(() => {
        if (open && activityId !== null) {
            refetchSchedules();
        }
    }, [open, activityId, refetchSchedules]);

    useEffect(() => {
        //console.log('Schedules:', schedules);
        //console.log('Schedules Error:', schedulesError);
    }, [schedules, schedulesError]);

    const handleAssignMentee = async (blockId: number) => {
        let new_alert = { id: `assigning_mentee_${Date.now()}`, type: "loading", title: "Registrando bloque de actividad", desc: "", close: false, timeout: 0 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            await assignMenteeToBlock({ menteeId: user_id, blockId }).unwrap();
            //console.log(`Bloque con id ${blockId} ha sido registrado por usuario con id ${user_id}`);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `mentee_assigned_${Date.now()}`, type: "success", title: "Bloque registrado con éxito", desc: "El bloque de la actividad ha sido registrado", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: new_alert2 }));
            refetchSchedules();
            setOpen(false);
            setOpenBlocks(false);
        } catch (error) {
            console.error("Error asignando mentee al bloque:", error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `assign_mentee_error_${Date.now()}`, type: "error", title: "Error al registrar el bloque", desc: "Hubo un error al registrar el bloque", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: new_alert2 }));
        }
    };

    const handleViewBlocks = (startTime: string, endTime: string) => {
        const blocks = schedules?.filter((schedule: any) => {
            return schedule.startTime === startTime && schedule.endTime === endTime;
        }) ?? [];
        setBlockData(blocks);
        setOpenBlocks(true);
    };

    const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
        return error && 'status' in error;
    };

    const renderCellWithTooltip = (params: GridRenderCellParams<any>) => (
        <Tooltip title={params.value}>
            <span>{params.value}</span>
        </Tooltip>
    );

    const columnas: GridColDef[] = [
        { headerName: "Evento", field: "event", flex: 1, minWidth: 250, renderCell: renderCellWithTooltip },
        { headerName: "Actividad", field: "activity", flex: 1, minWidth: 350, renderCell: renderCellWithTooltip },
        { headerName: "Tipo", field: "type", flex: 1, minWidth: 150, renderCell: renderCellWithTooltip },  
        {
            headerName: "Inicio", field: "startTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues: any) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Fin", field: "endTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues: any) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        { headerName: "Industria", field: "industry", flex: 1, minWidth: 250, renderCell: renderCellWithTooltip },
        { headerName: "Descripción", field: "description", flex: 2, minWidth: 450, renderCell: renderCellWithTooltip },
        {
            headerName: "Opciones", field: "opciones", flex: 1, minWidth: 140, maxWidth: 160,
            renderCell: (cellvalues: any) => (
                <div className='d-flex flex-column justify-content-center align-items-stretch w-100'>
                    <button
                        className="btn btn-primary btn-block p-1"
                        style={{
                            fontSize: '14px', 
                            padding: '10px', 
                            width: '100px',
                            backgroundColor: cellvalues.row.statusId === 3 || cellvalues.row.statusId === 5 ? '#ccc' : '#007bff',
                            color: '#fff',
                            cursor: cellvalues.row.statusId === 3 || cellvalues.row.statusId === 5 ? 'not-allowed' : 'pointer'
                        }}
                        onClick={() => {
                            handleViewSchedules(cellvalues.row.id);
                            setActivityId(cellvalues.row.id);
                            setOpen(true);
                        }}
                        disabled={cellvalues.row.statusId === 3 || cellvalues.row.statusId === 5}
                    >
                        Ver horarios
                    </button>
                </div>
            )
        }
    ];

    const scheduleColumns: GridColDef[] = [
        { headerName: "Bloque", field: "block", flex: 1, minWidth: 120, maxWidth: 150, renderCell: renderCellWithTooltip },
        { headerName: "Slots", field: "slots", flex: 0.5, minWidth: 50, maxWidth: 60, renderCell: renderCellWithTooltip },
        {
            headerName: "Opción", field: "option", flex: 0.5, minWidth: 100, maxWidth: 120,
            renderCell: (cellvalues: any) => (
                <button
                    className="btn btn-primary btn-block p-1"
                    style={{ fontSize: '14px', padding: '10px', width: '90px' }}
                    onClick={() => handleViewBlocks(cellvalues.row.startTime, cellvalues.row.endTime)}
                >
                    Ver bloques
                </button>
            )
        }
    ];

    const blockColumns: GridColDef[] = [
        { headerName: "Bloque", field: "block", flex: 1, minWidth: 150, maxWidth: 200, renderCell: renderCellWithTooltip },
        {
            headerName: "Experiencia laboral", field: "mentor", flex: 1, minWidth: 250, maxWidth: 500,
            renderCell: (cellvalues: any) => (
                <div>
                    {cellvalues.row.mentorIndustries && cellvalues.row.mentorIndustries.length > 0 ? (
                        cellvalues.row.mentorIndustries.map((industry: string, index: number) => (
                            <div key={index}>{industry}</div>
                        ))
                    ) : (
                        <div>N/A</div>
                    )}
                </div>
            )
        },
        {
            headerName: "Opción", field: "option", flex: 1, minWidth: 80, maxWidth: 100,
            renderCell: (cellvalues: any) => (
                <button
                    className="btn btn-primary btn-block p-1"
                    style={{ fontSize: '14px', padding: '10px', width: '80px' }}
                    onClick={() => handleAssignMentee(cellvalues.row.id)}
                >
                    Inscribir
                </button>
            )
        }
    ];

    const tableData = activities?.map((activity: any) => ({
        id: activity.activityId,
        event: activity.eventName,
        activity: activity.activityName,
        type: activity.type, 
        startTime: activity.startTime,
        endTime: activity.endTime,
        industry: activity.industryName,
        description: activity.description,
    })) || [];

    const groupSchedules = (schedules: any[]) => {
        const grouped = schedules.reduce((acc: any, schedule: any) => {
            const key = `${schedule.startTime}-${schedule.endTime}`;
            if (!acc[key]) {
                acc[key] = { ...schedule, slots: 0 };
            }
            acc[key].slots += 1;
            return acc;
        }, {});

        return Object.values(grouped);
    };

    const scheduleData = schedules ? groupSchedules(schedules).map((schedule: any) => ({
        id: schedule.id,
        startTime: schedule.startTime,
        endTime: schedule.endTime,
        block: `${moment(schedule.startTime).format("HH:mm")} - ${moment(schedule.endTime).format("HH:mm")}`,
        slots: schedule.slots,
    })) : [];

    const blockDataTransformed = blockData.map((block: any) => ({
        id: block.id,
        block: `${moment(block.startTime).format("HH:mm")} - ${moment(block.endTime).format("HH:mm")}`,
        mentor: block.userMentor ? `${block.userMentor.name} ${block.userMentor.lastName}` : 'N/A',
        mentorIndustries: block.userMentor ? block.userMentor.industries : []
    }));

    return isLoadingActivities ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : (
        <div className='table-container' style={{ width: '100%', overflowX: 'auto' }}>
            <Tabla columnas={columnas} datos={tableData}></Tabla>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
                <DialogTitle>Horarios</DialogTitle>
                <DialogContent>
                    {isLoadingSchedules ? (
                        <p>Cargando horarios...</p>
                    ) : (
                        schedules?.length === 0 ? (
                            <p>Actualmente, no hay horarios disponibles</p>
                        ) : (
                            scheduleData.length > 0 ? (
                                <Tabla columnas={scheduleColumns} datos={scheduleData}></Tabla>
                            ) : (
                                <p>Actualmente, no hay horas disponibles</p>
                            )
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openBlocks} onClose={() => setOpenBlocks(false)} maxWidth="md" fullWidth>
                <DialogTitle>Bloques</DialogTitle>
                <DialogContent>
                    <Tabla columnas={blockColumns} datos={blockDataTransformed}></Tabla>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenBlocks(false)} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ActivityTableMentee;
