import "./Landing.scss"
import LandingInicio from "./LandingInicio";
import LandingRoles from "./LandingRoles";
import LandingFormulaExperiencia from "./LandingFormulaExperiencia";
import LandingQuienesSomos from "./LandingQuienesSomos";
import LandingEquipo from "./LandingEquipo";
import LandingFooter from "./LandingFooter";
import LandingSponsorsPartners from "./LandingSponsorsPartners";
import LandingComunidad from "./LandingComunidad";
import LandingPerfiles from "./LandingPerfiles";
import LandingEventos from "./LandingEventos";
import { useGetAppStatsMutation } from "../../redux/features/dimensions/dimensionsApiSlice";
import { useEffect, useState } from "react";


const Landing = () => {

    const [getAppStats]: any = useGetAppStatsMutation()
    const [AppStats, setAppStats] = useState<any>([])
    const [countGetAppStats, setcountGetAppStats] = useState<number>(0)


    const fetch = async () => {
        const resAppStats: any = await getAppStats();
        if (resAppStats.data.statusCode === 200) {
            const resAppStatsJson = JSON.parse(resAppStats.data.responseData)
            setAppStats(resAppStatsJson)
        }
    }

    useEffect(() => {
        if (countGetAppStats == 0) {
            fetch()
            setcountGetAppStats(1)
        }
    }, [AppStats]);

    return (
        <>
            <div className="body-container ">
                {/* <div className="landing-container w-100"> */}
                <LandingInicio></LandingInicio>
                <LandingComunidad></LandingComunidad>
                <LandingPerfiles></LandingPerfiles>
                <LandingEventos></LandingEventos>
                {/*
                <LandingRoles></LandingRoles>
                <LandingFormulaExperiencia AppStats={AppStats}></LandingFormulaExperiencia>
                <LandingQuienesSomos></LandingQuienesSomos>
                <LandingEquipo></LandingEquipo>
                <LandingSponsorsPartners></LandingSponsorsPartners>*/}
                <LandingFooter></LandingFooter>
                {/* </div> */}
            </div>
        </>
    )
}

export default Landing;