import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import { useGetAllActivitiesQuery, useGetActivityAvailableTimesMentorQuery, useAssignMentorToBlockMutation } from '../../redux/features/events/eventsApiSlice';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
import Tabla from '../../components/Tabla/Tabla';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import './ActivityTableMentor.scss'; // Importar estilos para mantener el mismo formato

interface ActivityTableMentorProps {
    handleViewSchedules: (id: number) => void;
}

const ActivityTableMentor: React.FC<ActivityTableMentorProps> = ({ handleViewSchedules }) => {
    const dispatch = useDispatch();
    const user_personal_data = useSelector(selectPersonalData);
    const user_id = user_personal_data.Id;
    const [open, setOpen] = useState(false);
    const [activityId, setActivityId] = useState<number | null>(null);
    const { data: activities, isLoading, refetch: refetchActivities } = useGetAllActivitiesQuery({});
    
    const { data: schedules, isLoading: isLoadingSchedules, error: schedulesError, refetch: refetchSchedules } = useGetActivityAvailableTimesMentorQuery(activityId ?? -1, {
        skip: activityId === null,
    });
    const [assignMentorToBlock] = useAssignMentorToBlockMutation();

    useEffect(() => {
        refetchActivities();
    }, [refetchActivities]);

    useEffect(() => {
        if (open && activityId !== null) {
            refetchSchedules();
        }
    }, [open, activityId, refetchSchedules]);

    const handleAssignMentor = async (blockId: number) => {
        let new_alert = { id: `assigning_mentor_${Date.now()}`, type: "loading", title: "Registrando bloque de actividad", desc: "", close: false, timeout: 0 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            await assignMentorToBlock({ mentorId: user_id, blockId }).unwrap();
            //console.log(`Bloque con id ${blockId} ha sido registrado por usuario con id ${user_id}`);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `mentor_assigned_${Date.now()}`, type: "success", title: "Bloque registrado con éxito", desc: "El bloque de la actividad ha sido registrado", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: new_alert2 }));
            refetchSchedules();  
        } catch (error) {
            console.error("Error asignando mentor al bloque:", error);
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `assign_mentor_error_${Date.now()}`, type: "error", title: "Error al registrar el bloque", desc: "Hubo un error al registrar el bloque", close: true, timeout: 2000 };
            dispatch(addAlert({ alert: new_alert2 }));
        }
    };

    const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
        return error && 'status' in error;
    };

    const renderCellWithTooltip = (params: GridRenderCellParams<any>) => (
        <Tooltip title={params.value}>
            <span>{params.value}</span>
        </Tooltip>
    );

    const columnas: GridColDef[] = [
        { headerName: "Evento", field: "event", flex: 1, minWidth: 300, renderCell: renderCellWithTooltip },
        { headerName: "Actividad", field: "activity", flex: 1, minWidth: 300, renderCell: renderCellWithTooltip },
        {
            headerName: "Fecha Inicio", field: "startTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues: any) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Fecha Fin", field: "endTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues: any) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        { headerName: "Industria", field: "industry", flex: 1, minWidth: 300, renderCell: renderCellWithTooltip },
        { headerName: "Descripción", field: "description", flex: 2, minWidth: 500, renderCell: renderCellWithTooltip },
        {
            headerName: "Opciones", field: "opciones", flex: 1, minWidth: 120, maxWidth: 140,
            renderCell: (cellvalues: any) => (
                <div className='d-flex flex-column justify-content-center align-items-stretch w-100'>
                    <button
                        className="btn btn-primary btn-block p-1"
                        style={{
                            fontSize: '14px', 
                            padding: '10px', 
                            width: '100px',
                            backgroundColor: cellvalues.row.statusId === 3 || cellvalues.row.statusId === 5 ? '#ccc' : '#007bff',
                            color: '#fff',
                            cursor: cellvalues.row.statusId === 3 || cellvalues.row.statusId === 5 ? 'not-allowed' : 'pointer'
                        }}
                        onClick={() => {
                            handleViewSchedules(cellvalues.row.id);
                            setActivityId(cellvalues.row.id);
                            setOpen(true);
                        }}
                        disabled={cellvalues.row.statusId === 3 || cellvalues.row.statusId === 5}
                    >
                        Ver horarios
                    </button>
                </div>
            )
        }
    ];

    const tableData = activities?.map((activity: any) => ({
        id: activity.activityId,
        event: activity.eventName,
        activity: activity.activityName,
        startTime: activity.startTime,
        endTime: activity.endTime,  
        industry: activity.industryName,
        description: activity.description,
    })) || [];

    const groupedSchedules = schedules?.reduce((acc: any, schedule: any) => {
        const key = `${schedule.startTime}-${schedule.endTime}`;
        if (!acc[key]) {
            acc[key] = { ...schedule, slots: 1 };
        } else {
            acc[key].slots += 1;
        }
        return acc;
    }, {});

    const scheduleColumns: GridColDef[] = [
        { headerName: "Bloque", field: "block", flex: 1, minWidth: 150, renderCell: renderCellWithTooltip },
        { headerName: "Slots", field: "slots", flex: 1, minWidth: 100, renderCell: renderCellWithTooltip },  
        {
            headerName: "Opción", field: "option", flex: 0.5, minWidth: 80, maxWidth: 100,
            renderCell: (cellvalues: any) => (
                <button
                    className="btn btn-primary btn-block p-1"
                    style={{
                        fontSize: '14px', 
                        padding: '10px', 
                        width: '80px'
                    }}
                    onClick={() => handleAssignMentor(cellvalues.row.id)}
                >
                    Inscribir
                </button>
            )
        }
    ];

    const scheduleData = Object.values(groupedSchedules || {}).map((schedule: any) => ({
        id: schedule.id,
        block: `${moment(schedule.startTime).format("HH:mm")} - ${moment(schedule.endTime).format("HH:mm")}`,
        slots: schedule.slots 
    }));

    return isLoading ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : (
        <div className='table-container' style={{ width: '100%', overflowX: 'auto' }}>
            <Tabla columnas={columnas} datos={tableData}></Tabla>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <DialogTitle>Horarios</DialogTitle>
                <DialogContent>
                    {isLoadingSchedules ? (
                        <p>Cargando horarios...</p>
                    ) : (
                        schedulesError && isFetchBaseQueryError(schedulesError) && schedulesError.status === 404 ? (
                            <p>Actualmente, no hay horas disponibles</p>
                        ) : (
                            scheduleData.length > 0 ? (
                                <Tabla columnas={scheduleColumns} datos={scheduleData}></Tabla>
                            ) : (
                                <p>Actualmente, no hay horas disponibles</p>
                            )
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ActivityTableMentor;
