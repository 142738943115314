import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import SettingsLayoutItem from './SettingsLayoutItem';
import './SettingsLayoutSidebar.scss'
import QRCode from 'qrcode.react';
import opciones_navegacion from "../../../utils/settingsOptions.json"
import { useSelector } from "react-redux";
import { selectPersonalData, selectPublicData, setUserPublicData } from '../../../redux/features/user/userSlice';
 


/* mui */
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List'
import { useDispatch } from 'react-redux';
import { useGetUserPublicDataMutation } from '../../../redux/features/user/userApiSlice';
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';


// helpmodal
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@mui/material';


// CodigoQR



function SettingsLayoutSidebar({ handleClickOption }: any) {


	// helpmodal 
	const [openHelpModal, setOpenHelpModal] = useState(false);
	const handleOpenHelpModal = () => {
		setOpenHelpModal(true);
	};

	const handleCloseHelpModal = () => {
		setOpenHelpModal(false);
	};



	const dispatch = useDispatch()
	const textMenus = useSelector(selectCurrentDictonary).menu_items

	const isSpanish = textMenus.btn_cerrarmodal === "Cerrar";
	const manualUsuarioLink = isSpanish
		? `${process.env.PUBLIC_URL}/media/manualUsuario/ETMmeet - Manual de usuario.pdf` // Ruta para el manual en español
		: `${process.env.PUBLIC_URL}/media/manualUsuario/ETMmeet - User manual.pdf`; // Ruta para el manual en inglés

	// personal data vars
	const user_personal_data = useSelector(selectPersonalData)
	const user_id =user_personal_data.Id

	//inicio modal QR

	const [openQRModal, setOpenQRModal] = useState(false);

	const idToQR = user_id; // 

	const handleOpenQRModal = () => setOpenQRModal(true);
	const handleCloseQRModal = () => setOpenQRModal(false);

	
	const onScanSuccess = (decodedText: string, decodedResult: any) => {
		// console.log(`Decoded Text: ${decodedText}`, decodedResult);
		
	};
	
	const onScanError = (error: any) => {
		 
		if (error !== "NotFoundException") {
		  
		  console.error(error);
		}else{
			// console.log("error exceptionnn")
		}
	 
	  } 


	//fin modal QR

	
	 
	// fin modal scaner QR
	const [getUserPublicData, { isLoadingGetUserPublicDataAux }]: any = useGetUserPublicDataMutation()
	const [isLoadingGetUserPublicData, setIsLoadingGetUserPublicData] = useState(false)
	const user_public_data = useSelector(selectPublicData)
	const fetchUserPublicData = async (user_id: number) => {
		setIsLoadingGetUserPublicData(true)
		try {
			const res: any = await getUserPublicData({ user_id: user_id });
			const resdata = JSON.parse(res.data.responseData)
			dispatch(setUserPublicData({ user_public_data: resdata }))
		} catch (error) {
			console.log(error)
		}
		setIsLoadingGetUserPublicData(false)
	}

	useEffect(() => {
		if (!user_public_data) {
			fetchUserPublicData(user_id)
		}
		/*  */
	}, []);

	/* 
	user card:
		seccion que contiene:
		- foto y nombre del usuario
		- nivel de completitud de su perfil
		- botones para ir a perfil publico y cerrar secion
	*/
	let user_card_html = <div className='d-flex flex-column align-items-center justify-content-center'>
		{/* <div className='settingsSidebar-img mb-2'>
		</div> */}
		<div className='d-flex justify-content-center align-items-center mb-3'>
			<Avatar
				alt={user_personal_data.Name}
				src={(user_public_data && user_public_data.ProfilePicture) ? user_public_data.ProfilePicture : "/media/iconos/icono-persona.svg"}
				sx={{ width: 56, height: 56 }}
			/>
		</div>
		<h5 className='mb-2'>
			{(user_personal_data.Name ? user_personal_data.Name.split(" ")[0] : "") + " " + (user_personal_data.LastName ? user_personal_data.LastName.split(" ")[0] : "")}
		</h5>
		<div className='mt-3 d-flex justify-content-center'>
			<NavLink to="/user-profile" className='btn btn-sm btn-rounded btn-primary mr-2 px-3'>
				<div className='d-flex justify-content-center align-items-center'>
					<div className='mr-2'>
						<RemoveRedEyeOutlinedIcon fontSize="small" />
					</div>
					<p>{textMenus.btn_perfil}</p>

				</div>
			</NavLink>
			<NavLink to="/sign-out" className='btn btn-sm btn-rounded btn-danger px-3'>
				<div className='d-flex justify-content-center align-items-center'>
					<div className='mr-2'>
						<MeetingRoomOutlinedIcon fontSize="small" />
					</div>
					<p>{textMenus.btn_salir}</p>
				</div>
			</NavLink>
		</div>
	</div>


	/* 
		navegacion_html
	*/
	function navegacion_html() {
		return (

			<List component="nav" disablePadding>


				<SettingsLayoutItem
					name={textMenus.menu1}
					link="/settings/user-personal-data"
					handleClickOption={handleClickOption}
				/>
				<SettingsLayoutItem
					name="Generar Código QR"
					handleClickOption={handleOpenQRModal}
				/>

				{/* <SettingsLayoutItem
					name="Contactos"
					handleClickOption={handleClickOption}
				/> */}
				<SettingsLayoutItem
					name={textMenus.menu2}
					link="/settings/user-public-profile"
					handleClickOption={handleClickOption}
				/>
				<SettingsLayoutItem
					name={textMenus.menu3}
					link="/settings/user-roles"
					handleClickOption={handleClickOption}
				/>
				<SettingsLayoutItem
					name={textMenus.menu4}
					link="/settings/user-experience"
					handleClickOption={handleClickOption}
				/>
				<SettingsLayoutItem
					name={textMenus.menu5}
					link="/settings/user-startups"
					handleClickOption={handleClickOption}
				/>
				<SettingsLayoutItem
					name={textMenus.ayuda}
					handleClickOption={handleOpenHelpModal}
				/>


			</List>
		)
	}

	/* RETURN */
	return (
		<div className="py-2 px-2">
			<div className=''>
				{user_card_html}
				<hr />
				{navegacion_html()}
			</div>
			<Dialog open={openHelpModal}
			 fullWidth
			 maxWidth="md"
				onClose={handleCloseHelpModal}>
				<DialogTitle>{textMenus.titulo_modal}</DialogTitle>
				<DialogContent>

					<p>{textMenus.contacto_soporte}</p>

					<br></br>
					<a href={manualUsuarioLink} target="_blank" rel="noopener noreferrer" className="manual-usuario-link">
						{textMenus.ver_manual}
					</a>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseHelpModal} color="primary">
						{textMenus.btn_cerrarmodal}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={openQRModal} onClose={handleCloseQRModal}>
				<DialogTitle style={{ textAlign: 'center' }}>Mi código QR</DialogTitle>
				<DialogContent style={{ textAlign: 'center' }}>
				<QRCode value={"etmmeet.org/user-profile/" + user_id.toString() + "?mode=QR"} size={200}/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseQRModal}>Cerrar</Button>
				</DialogActions>
			</Dialog>
 


		</div>
	);
}
export default SettingsLayoutSidebar;
